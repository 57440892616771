import moment from 'moment'
// import { humanFileSize } from '@/helpers/utils';

export default {
    filters: {
        money(value) {
            return Number(value).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) || '0.00'
        },
        number(value) {
            return Number(value).toLocaleString('en-US')
        },
        percent(value) {
            // return value * 100 + "%"
            return `${Math.round(+value * 10000) / 100}%`
        },
        percentWriterRank(value) {
            return `${Math.round(+value * 10, 2)}%`
        },
        moment_from(value) {
            return moment(moment(value)).fromNow(true)
        },
        moment_full_text(value) {
            return moment(value).format('LLLL')
        },
        moment_short_text(value) {
            return moment(value).format('ddd, MMM D, YY h:mm A')
        },
        moment_bidding_text(value) {
            return moment(value).format('MMM D, YYYY')
        },
        moment_bidding_due_at(value) {
            return moment(value).format('D, MMMM YYYY')
        },
        file_size(value) {
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
            if (value === 0) return 'n/a'
            const i = parseInt(Math.floor(Math.log(value) / Math.log(1024)), 10)
            if (i === 0) return `${value} ${sizes[i]}`
            return `${(value / (1024 ** i)).toFixed(0)} ${sizes[i]}`
        }
    }
}
