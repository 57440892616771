import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { eventBus } from '@/helpers/event-bus/'
import { checkout } from '@/store/modules/checkout/index.js'
import { config } from '@/store/modules/config/index.js'
import { client } from '@/store/modules/client/index.js'
import { order } from '@/store/modules/order/index.js'
import { support } from '@/store/modules/support/index.js'
import { phone } from '@/store/modules/phone/index.js'
import { email } from '@/store/modules/email/index.js'
import { locales } from '@/store/modules/locales/index.js'
import { messenger } from '@/store/modules/messenger/index.js'
import { notification } from '@/store/modules/notification/index.js'
import { VuexHelper } from '@/helpers/vuex/index.js'

function checkerBuildId() {
    const instance = new VuexHelper(process.env.VUE_APP_BUILD)
    if (instance.hasChanged()) {
        console.log('%c Resetting vuex store!', 'background: #222; color: #bada55')
        setTimeout(() => {
            eventBus.$emit('showSnackBar', 'The app has been updated!', 'success')
        }, 1000);
        instance.reset()
        instance.setNewId()
        return true
    }
    return false
}

checkerBuildId()

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [
        createPersistedState()
    ],
    strict: process.env.NODE_ENV !== 'production',
    modules: {
        checkout,
        config,
        client,
        order,
        support,
        phone,
        locales,
        notification,
        email,
        messenger
    }
})
