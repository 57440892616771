import CloseIcon from 'mdi-vue/Close'
import Error from '@/components/icons/Error';
import Success from '@/components/icons/Success';
import Loading from '@/components/icons/Loading';

const Icons = {
    install(Vue) {
        Vue.component(CloseIcon.name, CloseIcon)
        Vue.component(Error.name, Error)
        Vue.component(Success.name, Success)
        Vue.component(Loading.name, Loading)
    }
}

export default Icons
