import Api from '@/helpers/api/index.js'
import {
    CLICK_MESSAGE
} from './action-types.js'

export const actions = {
    async [CLICK_MESSAGE](ctx, payload) {
        Api.put('/api/cp/notification/click-messages', payload)
    }
}
