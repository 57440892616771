<template>
    <div
        class="row align-center"
    >
        <div class="col-6 specific_order">
            <form-switch
                id="chk_linked"
                v-model="chk_linked"
            />
            <span class="form-switch_text">{{ label }}</span>
        </div>
        <!-- orderid -->
        <div
            v-if="chk_linked"
            class="col-6"
            :class="errors ? 'form-select_order' : ''"
        >
            <form-select-project
                v-model="$v.linked_to_order.$model"
                label="Pretype order ID or choose from the list"
                :styles="false"
                :errors="errors"
                name="linked_to_order"
                @change.native="$v.linked_to_order.$touch()"
                @blur.native="$v.linked_to_order.$touch()"
            />
        </div>
    </div>
</template>

<script>
import { eventBus } from '@/helpers/event-bus/'

import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import formValidationMixin from '@/mixins/formValidationMixin'
import { validationRules } from '@/validation/support/Rules'
import { formMessages } from '@/validation/support/Messages'
import Api from '@/helpers/api/index.js';
import filtersMixin from '@/mixins/filtersMixin.js'

import FormSwitch from '@/components/common/form-elements/FormSwitch';
import {
    mapSupportFields
} from '@/store/modules/support';

export default {
    components: {
        FormSwitch
    },
    mixins: [
        filtersMixin,
        validationMixin,
        formValidationMixin
    ],
    validations: {
        ...validationRules
    },
    props: {
        label: {
            type: String
        },
        errors: {
            type: String
        }
    },
    data: () => ({
        orders: [],
        inputLoading: false,
        search: null
    }),
    computed: {
        ...mapSupportFields([
            'form_data.data_support.chk_linked',
            'form_data.data_support.linked_to_order'
        ])
    },
    watch: {
        search(val) {
            val && val !== this.select && val.length > 3 && this.querySelections(val)
        }
    },
    async created() {
        await this.getInitialOrdersList()
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        async querySelections(queryString) {
            try {
                this.inputLoading = true
                const { data } = await Api.get('/api/order/list-by-params', {
                    query: queryString
                })
                this.orders = data.data
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.inputLoading = false
            }
        },
        async getInitialOrdersList() {
            try {
                this.inputLoading = true
                const { data } = await Api.get('/api/order/list-by-params', {
                    mode: 'list',
                    per_page: 100
                })
                this.orders = data.data
            } catch (error) {
                console.error(error)
            } finally {
                this.inputLoading = false
                this.$forceUpdate()
            }
        }
    }
}
</script>

<style lang="scss">
    .quote-form__form-col-100 {
        width: 100%;
    }
    .form-select_order {
        position: relative;
        .form-select {
            border: $input-border-error;
        }
    }
</style>
