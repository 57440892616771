import { createHelpers } from 'vuex-map-fields';

import { state } from './state.js';
import { getters } from './getters.js';
import { mutations } from './mutations.js';

export const { mapFields: mapPhoneFields } = createHelpers({
    getterType: 'phone/getField',
    mutationType: 'phone/updateField'
});

export const phone = {
    namespaced: true,
    state() {
        return { ...state }
    },
    mutations,
    getters
};
