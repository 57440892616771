<template>
    <div class="support-messenger_chat__bot">
        <div class="support-messenger_chat__bot-avatar">
            <img
                src="/img/bot.gif"
                alt=""
            >
        </div>
        <p class="support-messenger_chat__bot-text">
            Did you know that I am here 24/7? My job is to make sure no case is left unattended. Tell me more so I can call the right "Support Hero"
        </p>
    </div>
</template>

<script>
export default {

}
</script>
