<template>
    <svg
        width="16"
        height="12"
        viewBox="0 0 16 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5.50013 9.46803L2.02513 5.99303L0.841797 7.16803L5.50013 11.8264L15.5001 1.82637L14.3251 0.651367L5.50013 9.46803Z"
            fill="#FF5925"
        />
    </svg>
</template>

<script>
export default {
    name: 'Check'
}
</script>
<style scoped lang='scss'>
svg path {
    fill: $main-color;
}
</style>
