import { forEach } from 'lodash';
import { eventBus } from '@/helpers/event-bus/';

// import Vuelidate from 'vuelidate'
// import { validationMixin } from 'vuelidate'

import formFields from '@/helpers/FormFieldNames.js';

// import { scroller } from 'vue-scrollto/src/scrollTo'
const touchMap = new WeakMap();

export default {
    components: {
    // Vuelidate
    },
    mixins: [
    // validationMixin,
    ],
    data: () => ({
        snackbar: false,
        snackbar_message: ''
    }),
    computed: {

    },
    watch: {
    },
    filters: {
    },
    methods: {
        delayTouch($v) {
            $v.$reset();
            if (touchMap.has($v)) {
                clearTimeout(touchMap.get($v));
            }
            touchMap.set($v, setTimeout($v.$touch, 1000));
        },
        focusFirstStatus(component = this) {
            const a = component.errorMessages;
            if (typeof a !== 'undefined' && a instanceof Array) {
                if (a.length > 0) {
                    // this scrolls the page to the first error and is present on all forms on the site
                    // const firstScrollTo = scroller()
                    // firstScrollTo(component.$el, 500, {
                    //     offset: -100
                    // })
                    return true;
                }
            }
            let focused = false;
            component.$children.some((childComponent) => {
                focused = this.focusFirstStatus(childComponent);
                return focused;
            });
            return focused;
        },
        validations() {
            return new Promise((resolve) => {
                if (this.$v.$error || !this.$v.$pending) {
                    return resolve();
                }
                const poll = setInterval(() => {
                    if (!this.$v.$pending) {
                        clearInterval(poll);
                        resolve();
                    }
                }, 200);
                return poll
            });
        },
        async isValid() {
            this.$v.$reset();
            this.$v.$touch();
            await this.validations();
            return Promise.resolve(!this.$v.$error);
        },
        async validate() {
            // check phone first
            const isValid = await this.isValid();
            if (isValid) {
                this.submit();
                // if (password) {
                //     this.checkPassword()
                // }
                // else {

                // }
            } else {
                // this.$v.$touch();
                // let focusOnError = this.$nextTick(() => this.focusFirstStatus());
                const errorName = this.findNameOfFirstError(this.$v);
                eventBus.$emit('showSnackBar', `Please complete the form: ${errorName}`, 'error');
                throw new Error('form is invalid');
            }
        },

        findNameOfFirstError(items) {
            const vm = this;
            let listOfErrors = '';
            forEach(items, (item, key) => {
                try {
                    const result = vm.$v[key].$error;

                    if (result === true) {
                        const itemDisplayName = formFields[key] ? formFields[key] : key;
                        listOfErrors += `${itemDisplayName}, `;
                    }
                } catch (e) {
                    console.error(e);
                }
            });
            listOfErrors = listOfErrors.substring(0, listOfErrors.length - 2);
            return listOfErrors;
        }

    // async validateOrderFormStep() {
    //     return new Promise((resolve, reject) => {
    //
    //         // check phone first
    //         const isValid = this.isValid()
    //         if (isValid) {
    //             resolve(true)
    //         }
    //         else {
    //             this.$v.$touch();
    //             // let focusOnError = this.$nextTick(() => this.focusFirstStatus());
    //             let errorName = this.findNameOfFirstError(this.$v)
    //             eventBus.$emit('showSnackBar', 'Please complete the form: ' + errorName, 'error');
    //             reject(false)
    //         }
    //     })
    // },
    // async validateFeedbackFormStep() {
    //     return new Promise((resolve, reject) => {
    //
    //         // check phone first
    //         const isValid = this.isValid()
    //         if (isValid) {
    //             resolve(true)
    //         }
    //         else {
    //             this.$v.$touch();
    //             // let focusOnError = this.$nextTick(() => this.focusFirstStatus());
    //             let errorName = this.findNameOfFirstError(this.$v)
    //             eventBus.$emit('showSnackBar', 'Please complete the form: ' + errorName, 'error');
    //             reject(false)
    //         }
    //     })
    // }
    }
};
