export const RESET = 'RESET'

export const START = 'START'

export const SUCCESS = 'SUCCESS'

export const FAILURE = 'FAILURE'

export const SET_DEFAULT = 'SET_DEFAULT'

export const RESET_VERIFICATION = 'RESET_VERIFICATION'
