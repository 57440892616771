export class SchemaCreditCard {
    constructor({
        cc_type = '',
        cc_name = '',
        cc_number = '',
        cc_expiry = '',
        cc_cvv = '',
        cc_address = '',
        cc_zip = ''

    } = {}) {
        this.cc_type = cc_type;
        this.cc_name = cc_name;
        this.cc_number = cc_number;
        this.cc_cvv = cc_cvv;
        this.cc_expiry = cc_expiry;
        this.cc_address = cc_address;
        this.cc_zip = cc_zip;
    }
}

// export function createClient(data) {
//     return Object.freeze(new Client(data));
// }
