<template>
    <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :class="color ? '' : 'main-color'"
    >
        <path
            d="M12.0008 20H8.00075M16.0008 7C16.0008 5.4087 15.3686 3.88258 14.2434 2.75736C13.1182 1.63214 11.5921 1 10.0008 1C8.40945 1 6.88333 1.63214 5.75811 2.75736C4.63289 3.88258 4.00075 5.4087 4.00075 7C4.00075 10.0902 3.22122 12.206 2.35042 13.6054C1.61588 14.7859 1.24861 15.3761 1.26208 15.5408C1.27699 15.7231 1.31561 15.7926 1.46253 15.9016C1.59521 16 2.19334 16 3.38961 16H16.6119C17.8082 16 18.4063 16 18.539 15.9016C18.6859 15.7926 18.7245 15.7231 18.7394 15.5408C18.7529 15.3761 18.3856 14.7859 17.6511 13.6054C16.7803 12.206 16.0008 10.0902 16.0008 7Z"
            :stroke="color"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    name: 'Bell',
    props: {
        color: {
            type: String
        }
    }
}
</script>
<style scoped lang='scss'>
svg {
    width: 22px;
    height: 22px;
}
.main-color path {
    stroke: $main-color;
}
</style>
