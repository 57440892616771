import { updateField } from 'vuex-map-fields'
import { SchemaSupport } from '@/models/SchemaSupport.js'

import {
    CLEAR_FORM
} from './mutation-types.js'

export const mutations = {
    updateField,
    [CLEAR_FORM](state) {
        state.form_data = new SchemaSupport()
    }
}
