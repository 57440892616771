<template>
    <header class="header-nav">
        <nav class="header-nav__wrapper wrapper">
            <div class="header__wrapper">
                <div class="header__logo">
                    <a
                        :href="'https://' + getAppDomain"
                        class="header__logo-link header__logo-img"
                    />
                </div>
            </div>
            <div>
                <router-link
                    to="/"
                    class="btn-base btn-main"
                >
                    Account
                </router-link>
            </div>
        </nav>
    </header>
</template>
<script>
export default {
    computed: {
        getAppDomain() {
            return process.env.VUE_APP_DOMAIN;
        }
    }
}
</script>

<style lang="scss">
.header-nav {
    background: #fff;
    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include media480max {
            padding: 0 15px;
        }
    }
    .header__wrapper {
        padding-left: 0;
    }
}
</style>
