<template>
    <div class="hint-wrapper">
        <span class="hint-image">
            <info />
        </span>
        <p
            class="hint-text"
            v-html="text"
        />
    </div>
</template>
<script>
import Info from '@/components/icons/Info.vue'

export default {
    name: 'Hint',
    components: { Info },
    props: {
        text: {
            type: String
        }
    }
}
</script>
<style lang="scss">
    .hint {
        &-wrapper {
            display: flex;
            align-items: center;
            margin: 10px 0;
        }
        &-text {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            // display: flex;
            align-items: center;
            color: $font-color-main;
            padding-right: 60px;
        }
        &-image {
            display: flex;
            justify-content: center;
            align-items: center;
            // mask: url("~@/assets/img/svg/info.svg") no-repeat center;
            // background: $main-color;
            min-width: 44px;
            height: 30px;
            margin-right: 10px;
        }
    }
    @media (max-width: 768px) {
        .hint {
            &-wrapper {
                padding: 15px;
                background: #F8F8F8;
                border-radius: 4px;
                align-items: flex-start;
            }
            &-text {
                padding-right: 0;
            }
        }
    }
</style>
