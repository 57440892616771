<template>
    <div class="writers-messanger_list">
        <template v-if="list.length > 0">
            <div
                v-for="(chat) in list"
                :key="chat.id"
                class="writers-messanger_chat"
                @click="goToChat(chat.id)"
            >
                <div class="writers-messanger_chat__header">
                    <div class="writers-messanger_chat__header_top">
                        <div class="writers-messanger_chat_types">
                            <span v-if="chat.orderid">{{ chat.orderid }}</span>
                        </div>
                        <p class="writers-messanger_chat_date report">
                            <span class="writers-messanger_chat_date_tool tool">
                                {{ chat.created_at | moment_short_text }}
                            </span>
                            <span style="white-space: nowrap">{{ chat.created_at | moment_from }}</span>
                        </p>
                    </div>
                    <b class="writers-messanger_chat_subject">«{{ chat.title }}»</b>
                </div>
                <SupportMessengerLastMessage
                    :last-message="chat.last_message"
                    :unread-counter="chat.counters.unread_messages_count"
                />
            </div>
        </template>
        <no-result
            v-else
            type="writers-chat"
        />
    </div>
</template>

<script>
import filtersMixin from '@/mixins/filtersMixin.js'
import NoResult from '@/components/common/NoResult'
import SupportMessengerLastMessage from './SupportMessengerLastMessage'

export default {
    components: {
        SupportMessengerLastMessage,
        NoResult
    },
    mixins: [
        filtersMixin
    ],
    props: {
        list: {
            type: Array,
            required: true
        }
    },
    methods: {
        goToChat(id) {
            this.$emit('goToChat', id)
        },
        getLastMessageOwner(type) {
            return type.toLowerCase()
        }
    }
}
</script>
<style lang="scss">
.writers-messanger{
    &_list {
        height: 100%;
    }
    &_chat {
        padding: 10px 10px 20px 10px;
        font-size: 14px;
        cursor: pointer;
        border: 1px solid #EAEAEA;
        border-radius: 4px;
        margin-bottom: 16px;
        transition: box-shadow 0.2s, border 0.2s;
        &:hover {
            // background: rgba($main-color, 0.1);
            border-bottom: 1px solid transparent;
            border: 1px solid $main-color;
            box-shadow: inset 0px 0px 0px 4px rgba($main-color, 0.08);
            .writers-messanger_chat_types span{
                background: rgba($main-color, 0.3);
            }
            .writers-messanger_chat_subject {
                text-decoration: underline;
            }
        }
        &__header{
            &_top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;
                margin-bottom: 10px;
            }
        }
        &_types{
            display: flex;
            flex-wrap: wrap;
            span {
                margin-right:10px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                min-width: max-content;
                padding: 5px;
                border-radius: 5px;
                background-color: rgba($main-color, 0.1);
                color: $main-color;
                position: relative;
                &::after {
                    content: ">";
                    margin-right: 5px;
                    position: absolute;
                    right: -15px;
                }
                &:last-child{
                    &::after {
                        display: none;
                    }
                }
            }
        }
        &__body{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 10px;
        }
        &__body-last-message{
            display: flex;
            align-items: center;
            span {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: 14px;
                width: calc(100% - 20px);
                padding-left: 10px;
            }
            .avatar-img{
                width: 22px;
                height: 22px;
                border: 1px solid $main-color;
            }
            .avatar-img .default-avatar {
                width: 12px;
                background-color: $main-color;
            }
        }
        &_date{
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #61646A;
        }
        &_date_tool {
            font-size: 10px !important;
            left: calc(50% - 170px) !important;
            z-index: 9 !important;
        }
        &_subject {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 1.5;
        }
        &__bell{
            position: relative;
            margin-left: 10px;
            svg {
                width: 26px;
                height: 26px;
            }
        }
    }
}
</style>
