<template>
    <div class="priject-select">
        <div
            v-click-outside="clickOutside"
            class="form-select"
            :class="{ 'error-border': validationError, 'success-border' : selectedOrder, 'grey-border' : openListItem && !selectedOrder }"
            @click="openListItem = !openListItem"
        >
            <fieldset :class="[openListItem && !selectedOrder ? 'form-select__fieldset active' : 'form-select__fieldset', {'selected-value': selectedOrder}]">
                <legend
                    class="form-select__legend"
                    :class="{ 'error': validationError }"
                >
                    {{ label }}
                </legend>
                <component
                    :is="prependIcon"
                    class="form-input__prepend-icon"
                />
                <div class="form-select__input-block">
                    <!-- selected order -->
                    <div
                        v-if="selectedOrder"
                        class="form-select__input-selected_item"
                    >
                        <div
                            class="form-select__input-selected_item-title"
                            style="max-width: unset; width: auto"
                        >
                            <span>{{ selectedOrder.project_information.title }}</span>
                        </div>
                        <span class="form-select__input-selected_item-id">{{ selectedOrder.orderid }}</span>
                        <!-- clear field button -->
                        <img
                            src="@/assets/img/svg/cross.svg"
                            @click="clearField"
                        >
                    <!-- clear field button -->
                    </div>
                    <!-- selected order -->
                    <!-- pretype input -->
                    <input
                        v-show="!selectedOrder"
                        class="form-select__input"
                        :value="search"
                        type="text"
                        :name="name"
                        :placeholder="label"
                        autocomplete="off"
                        @focus="$event.target.select()"
                        @input="onInputChanged($event)"
                    >
                <!-- pretype input -->
                </div>
                <!-- preloaded results -->
                <ul
                    v-if="openListItem && !selectedOrder"
                    id="listElm"
                    class="form-select__list"
                    @scroll="loadMore"
                >
                    <li
                        v-for="(item, index) in orders"
                        :key="index"
                        class="form-select__list-item"
                        @click="selectItem(item)"
                    >
                        <p class="form-select__list-item-title">
                            <b>&#171;{{ item.project_information.title }}&#187;</b>
                        </p>
                        <p class="form-select__list-item-description">
                            {{ item.created_at | moment_from }} ago: {{ item.orderid }}, {{ item.status }}
                        </p>
                    </li>
                    <li
                        v-if="loading"
                        class="form-select__list--loading"
                    >
                        <ButtonLoader />
                    </li>
                </ul>
                <!-- preloaded results -->
                <!-- validation error -->
                <div
                    v-if="validationError"
                    class="form-select__error"
                >
                    {{ validationError }}
                </div>
            <!-- validation error -->
            </fieldset>
        </div>
    </div>
</template>

<script>
import { eventBus } from '@/helpers/event-bus/'
import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'
import ClickOutside from 'vue-click-outside';
import { debounce } from 'lodash'
import Api from '@/helpers/api/index.js'
import filtersMixin from '@/mixins/filtersMixin';
import ButtonLoader from '@/components/common/ButtonLoader.vue';

export default {
    name: 'FormSelectProject',
    components: {
        ButtonLoader
    },
    directives: {
        ClickOutside
    },
    mixins: [
        filtersMixin
    ],
    props: {
        value: {
            type: String
        },
        prependIcon: {
            type: String
        },
        label: {
            type: String
        },
        errors: {
            type: String
        },
        name: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            openListItem: false,
            selectedOrder: null,
            orders: [],
            lastpage: 0,
            loading: true,
            search: '',
            payload: {
                per_page: 5,
                page: 1
            }
        };
    },
    computed: {
        validationError() {
            return this.errors ? this.errors : '';
        }
    },
    watch: {
        search: debounce(async function () {
            this.payload = {
                ...this.payload,
                page: 1,
                search_by: 'orderid',
                search_for: this.search
            }
            this.lastpage = 0
            this.orders = []
            await this.getOrderList()
        }, 500)
    },
    async created() {
        const { linked_to_order } = this.$route.query
        if (linked_to_order) {
            await this.prependOrder(linked_to_order)
        } else if (this.value) {
            await this.prependOrder(this.value)
        } else {
            await this.getOrderList()
        }
    },
    methods: {
        selectItem(order) {
            this.$emit('input', order.orderid);
            this.selectedOrder = order
            this.openListItem = false;
        },
        onInputChanged(event) {
            this.openListItem = true;
            this.search = event.target.value;
            if (this.search === '') {
                this.$emit('input', '');
            }
        },
        clickOutside() {
            this.openListItem = false
        },
        async prependOrder(linked_to_order) {
            try {
                this.loading = true
                const { data } = await Api.get('/api/order/list-by-params', removeEmptyParamsFromRequest({
                    ...this.payload,
                    mode: 'list',
                    search_by: 'orderid',
                    search_for: linked_to_order
                }))
                this.orders = data.data
                this.selectedOrder = this.orders.find((order) => order.orderid === linked_to_order)
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loading = false
            }
        },
        async getOrderList() {
            try {
                this.loading = true
                const [{ data }, { data: totals }] = await Promise.all([
                    Api.get('/api/order/list-by-params', removeEmptyParamsFromRequest({ ...this.payload, mode: 'list' })),
                    Api.get('/api/order/list-by-params', removeEmptyParamsFromRequest({ ...this.payload, mode: 'count' }))
                ])
                this.lastpage = Math.ceil(totals.count / this.payload.per_page)
                this.orders = [...this.orders, ...data.data]
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loading = false
            }
        },
        async loadMore() {
            const listElm = document.querySelector('#listElm')
            const scrollHeightCheck = (listElm.scrollTop + listElm.clientHeight) >= listElm.scrollHeight - 50
            if (scrollHeightCheck && this.payload.page < this.lastpage && !this.loading) {
                this.payload.page += 1
                await this.getOrderList()
            }
        },
        async clearField() {
            this.search = ''
            this.selectedOrder = null
            this.payload = {
                ...this.payload,
                page: 1,
                search_by: 'orderid',
                search_for: ''
            }
            this.lastpage = 0
            this.orders = []
            await this.getOrderList()
            this.$emit('input', '')
        }
    }
};
</script>

<style lang="scss" >
.priject-select {
    position: relative;
    z-index: 11;
    .form-select {
        outline: none;
        // border: 1px solid #27d91f ;
        border: 1px solid #D3D9E2;
        -webkit-border-radius: $input-border-radius;
        -moz-border-radius: $input-border-radius;
        border-radius: $input-border-radius !important;
        max-height: 52px!important;
        width: 100%;

        &.success-border {
            border: $input-border-success;
        }
        &.grey-border {
            border: $input-border;
        }
        &__icon {
            position: absolute;
            top: 10px;
            left: 12px;
        }

        &__fieldset {
            position: relative;
            border: none ;
            outline: none;
            &.selected-value {
                &:after {
                    display: none;
                }
            }
        }

        &__legend {
            display: table;
            color: #b3bbc6;
            caret-color: #b3bbc6;
            padding: 2px 5px 2px 5px;
            font-size: 80%;
            position: absolute;
            left: 6px;
            top: -12px;
            background-color: $white;
            border: none;
        }

        &__input {
            box-sizing: border-box;
            display: inline-block;
            padding-left: 20px;
            width: 100%;
            cursor: pointer;
            border: none ;
            outline: none;
            -webkit-border-radius: $input-border-radius;
            -moz-border-radius: $input-border-radius;
            border-radius: $input-border-radius;
            font-family: $font-family-base, sans-serif ;
            font-size: 14px;
            max-height: 46px;
            height: 46px;
        }

        &__list {
            overflow-y: scroll;
            max-height: 270px;
            background-color: white;
            position: absolute;
            z-index: 10;
            top: 55px;
            text-align: left;
            width: 100%;
            margin: 0;
            padding: 0;
            -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
            border-radius: 4px;
            &--loading {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px 0;
                .loader {
                    width: 25px;
                    height: 25px;
                    border-top-color: $main-color;
                }
            }
        }

        &__list-item {
            padding: 15px;
            background-color: #fff;
            margin-bottom: 1px;
            cursor: pointer;
            border-bottom: 1px solid #e9e9e9;
            &:hover {
                background-color: lightgrey;
                //color: #FFF;
            }
            &--selected {
                background-color: rgba(230, 230, 230, 1);
                &:hover {
                    background-color: rgba(230, 230, 230, 1);
                    //color: #FFF;
                }
            }
        }
        &__list-item-title {
            margin-bottom: 2px;
            color: rgba(0,0,0,.8);
        }
        &__list-item-description {
            color: rgba(0,0,0,.6);
            margin-top: 6px;
        }
        &__error {
            display: inline;
            width: auto;
            text-align: left;
            color: $error-color;
            caret-color: $error-color;
            font-size: 12px;
            min-height: 14px;
            position: absolute;
            left: 13px;
            bottom: -7px;
            background: white;
            padding: 0 3px;
        }
    }
    .form-select__input-block {
        display: flex;
        align-items: center;
        width: 100%;
        max-height: 46px;
        height: 46px;
        padding-left: 5px;
        padding-right: 5px;
        .form-select__input-selected_item {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            padding-left: 10px;
            padding-right: 10px;
            cursor: pointer;
            border: none;
            outline: none;
            border-radius: 4px;
            font-family: $font-family-base, sans-serif;
            font-size: 14px;
            max-height: 46px;
            height: 36px;
            background: #F4F4F4;
            span {
                white-space: nowrap;
                line-height: 22px;
            }
            &-title {
                margin-right: 4px;
                overflow-x: hidden;
                text-overflow: ellipsis;
            }
            &-id {
                margin-right: 10px;
            }
            img {
                width: 10px;
                height: 10px;
            }
        }
        .form-select__input {
            padding-left: 10px;
        }
    }
}
</style>
