import { state } from './state.js';
import { mutations } from './mutations.js';
import { actions } from './actions.js';

export const locales = {
    namespaced: true,
    state() {
        return { ...state }
    },
    mutations,
    actions
};
