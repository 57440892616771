<template>
    <div class="form-switch">
        <input
            :id="id"
            class="form-switch__input"
            type="checkbox"
            :value="value"
            :checked="value"
            @change="$emit('input', $event.target.checked)"
        >
        <label
            class="form-switch__label"
            :for="id"
        />
    </div>
</template>

<script>
export default {
    name: 'FormSwitch',
    props: {
        value: {
            type: Boolean
        },
        id: {
            type: String
        }
    }
};
</script>

<style lang="scss" scoped>
.form-switch {
    input {
        display: none;

        &:checked + .form-switch__label {
            &::before {
                left: 27px;
                background: $main-color;
            }
        }
    }
    &__label {
        display: inline-block;
        position: relative;
        width: 49px;
        height: 19px;
        background: #EBEBEB;
        border-radius: 32px;
        transition: 0.5s;
        cursor: pointer;

        &::before {
            content: '';
            position: absolute;
            border-radius: 50%;
            background: #fafafa;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
            width: 27px;
            height: 27px;
            left: 0px;
            top: -5px;
            transition: 0.5s;
        }

    }
}
</style>
