import { cloneDeep } from 'lodash'

import { updateField } from 'vuex-map-fields';
import { SchemaOrder } from '@/models/SchemaOrder.js'
import { SchemaExpressOrder } from '@/models/SchemaExpressOrder.js'

import {
    RESET,
    SET_ACTIVE_STEP,
    LOADING_SET_STATUS,
    SET_PRESET,
    SET_ORDER_DETAILS_BREADCRUMBS,
    CLEAR_FORM_STANDARD,
    CLEAR_FORM_EXPRESS,
    SET_ORDER_FORM_DATA,
    CLEAR_FILES_TO_UPLOAD,
    SET_FILES_TO_FORM_DATA,
    CLEAR_ALL_FILES
} from './mutation-types.js'

import { initialState } from './state.js'

export const mutations = {
    updateField,
    [RESET](state) {
        Object.assign(state, cloneDeep(initialState))
    },
    [CLEAR_FORM_STANDARD](state) {
        state.form_data = new SchemaOrder()
    },
    [CLEAR_FORM_EXPRESS](state) {
        state.express_form_data = new SchemaExpressOrder()
    },
    [SET_ACTIVE_STEP](state, payload) {
        state.active_step = payload
    },
    [SET_ORDER_FORM_DATA](state, payload) {
        state.form_data = new SchemaOrder(payload)
    },
    [LOADING_SET_STATUS](state, payload) {
        state.loading.status = payload.status
        state.loading.message = payload.message
    },
    [SET_PRESET](state, payload) {
        state.selectedPreset = payload
    },
    [SET_ORDER_DETAILS_BREADCRUMBS](state, payload) {
        state.orderDetailsTitle = payload
    },
    [CLEAR_FILES_TO_UPLOAD](state) {
        state.files_add = []
    },
    [CLEAR_ALL_FILES](state) {
        state.files_add = []
        state.form_data.files_uploaded = []
    },
    [SET_FILES_TO_FORM_DATA](state, payload) {
        state.form_data.files_uploaded = [...state.form_data.files_uploaded, ...payload]
    }
}
