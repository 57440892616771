<template>
    <svg
        width="20"
        height="15"
        viewBox="0 0 20 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M18.294 4.292L13.994 8.584L12.702 7.292L11.288 8.706L13.994 11.41L19.706 5.708L18.294 4.292ZM2 4C2 6.28 3.72 8 6 8C8.28 8 10 6.28 10 4C10 1.72 8.28 0 6 0C3.72 0 2 1.72 2 4ZM8 4C8 5.178 7.178 6 6 6C4.822 6 4 5.178 4 4C4 2.822 4.822 2 6 2C7.178 2 8 2.822 8 4ZM2 14C2 12.346 3.346 11 5 11H7C8.654 11 10 12.346 10 14V15H12V14C12 11.243 9.757 9 7 9H5C2.243 9 0 11.243 0 14V15H2V14Z"
            fill="#FF5925"
        />
    </svg>
</template>
<script>
export default {
    name: 'Writers'
}
</script>
<style scoped lang='scss'>
svg {
    height: 21px;
    margin-right: 15px;
}
svg path {
    fill: $main-color;
}
</style>
