<template>
    <fieldset class="double-form-element">
        <slot />
    </fieldset>
</template>

<script>
export default {
    name: 'DoubleFormElement'
}
</script>

<style lang="scss">

.double-form-element {
    display: flex;
    border: $input-border;
    border-radius: $input-border-radius;

    &:focus-within {
        border: 1px solid #666;
    }
}

.double-form-element .form-input__input{
    border: none!important;
}

.double-form-element .form-select{
    border: none!important;
}

.filter-container .double-form-element .form-select.success-border {
    border: none!important;
}
</style>
