import Api from '@/helpers/api/index.js'
import { removeEmptyParamsFromRequest } from '@/helpers/utils/'

export const getGuestTicket = async (payload) => {
    const { data } = await Api.get('/api/support/guest/fetch-ticket', removeEmptyParamsFromRequest(payload))
    return data
}

export const setGuestTicket = (ticket) => {
    const data = localStorage.getItem('guest_ticket')
    const parsedData = data ? JSON.parse(data) : []
    parsedData.push(JSON.stringify(ticket))
    localStorage.setItem('guest_ticket', JSON.stringify(parsedData))
}
export const getGuestTicketFromStorage = () => {
    const data = localStorage.getItem('guest_ticket')
    const parsedData = JSON.parse(data) || []
    return parsedData.map((item) => JSON.parse(item))
}
export const removeGuestTicketFromStorage = (indexes) => {
    const data = localStorage.getItem('guest_ticket');
    const parsedData = JSON.parse(data) || [];
    indexes.forEach((index) => {
        parsedData.splice(index, 1, false)
    });

    localStorage.setItem('guest_ticket', JSON.stringify(parsedData.filter((item) => Boolean(item))))
}

export const checkDiff = (list1, list2) => {
    const list1Dates = list1.map((item) => item.messages[item.messages.length - 1].created_at)
    const list2Dates = list2.map((item) => item.messages[item.messages.length - 1].created_at)
    return list1Dates.join('') !== list2Dates.join('')
}

export const getSupportMessages = async (payload, isAuth) => {
    const api_path = isAuth ? '/api/support/auth/message/list' : '/api/support/guest/message/list'
    const { data } = await Api.get(api_path, removeEmptyParamsFromRequest(payload))
    return data
}

export const sendMessage = async (payload, isAuth) => {
    const api_path = isAuth ? '/api/support/auth/message/create' : '/api/support/guest/message/create'
    const { data } = await Api.post(api_path, removeEmptyParamsFromRequest({ ...payload }))
    return data
}

export const readTicket = async (payload, isAuth) => {
    const api_path = isAuth ? '/api/support/auth/mark-as-read' : '/api/support/guest/mark-as-read'
    await Api.put(api_path, removeEmptyParamsFromRequest(payload))
}

export const createTicket = async (payload, isAuth) => {
    const api_path = isAuth ? '/api/support/auth/create-ticket' : '/api/support/guest/create-ticket'
    const { data } = await Api.post(api_path, removeEmptyParamsFromRequest({
        ...payload,
        chk_callback: false
    }))
    return data
}
