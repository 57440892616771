<template>
    <div class="support-messenger_list">
        <div
            v-for="(ticket) in list"
            :key="ticket.ticketid"
            class="support-messenger_ticket"
            @click="goToChat(ticket.ticketid)"
        >
            <div class="support-messenger_ticket__header">
                <div class="support-messenger_ticket__header_top">
                    <div class="support-messenger_ticket_types">
                        <span v-if="ticket.long_names.type">{{ ticket.long_names.type }}</span>
                    </div>
                    <p class="support-messenger_ticket_date report">
                        <span class="support-messenger_ticket_date_tool tool">
                            {{ ticket.created_at | moment_short_text }}
                        </span>
                        <span style="white-space: nowrap">{{ ticket.created_at | moment_from }}</span>
                    </p>
                </div>
                <b class="support-messenger_ticket_subject">«{{ ticket.subject }}»</b>
            </div>
            <SupportMessengerLastMessage
                :last-message="ticket.last_message"
                :unread-counter="ticket.counters.unread_messages_count"
            />
        </div>
    </div>
</template>

<script>
import filtersMixin from '@/mixins/filtersMixin.js'
import SupportMessengerLastMessage from './SupportMessengerLastMessage'

export default {
    components: {
        SupportMessengerLastMessage
    },
    mixins: [
        filtersMixin
    ],
    props: {
        list: {
            type: Array,
            required: true
        }
    },
    methods: {
        goToChat(id) {
            this.$emit('goToChat', id)
        },
        getLastMessageOwner(type) {
            return type.toLowerCase()
        }
    }
}
</script>
