import Api from '@/helpers/api/index.js'
import { removeEmptyParamsFromRequest } from '@/helpers/utils/'

export const getWriterChatMessages = async (payload) => {
    const { data } = await Api.get('/api/chat-client-writer/list-messages', removeEmptyParamsFromRequest({ ...payload }))
    return data
}

export const sendMessage = async (payload) => {
    const { data } = await Api.put('/api/chat-client-writer/create-message', removeEmptyParamsFromRequest({ ...payload }))
    return data
}

export const readChat = async (payload) => {
    await Api.post('/api/chat-client-writer/mark-as-read', removeEmptyParamsFromRequest({ ...payload }))
}

export const createChat = async (payload) => {
    const { data } = await Api.put('/api/chat-client-writer/create-chat', removeEmptyParamsFromRequest({ ...payload }))
    return data.id
}

export const getWritersChatsList = async (payload) => {
    const { data } = await Api.get('/api/chat-client-writer/list-chats', removeEmptyParamsFromRequest({ ...payload }))
    return data
}

export const onlineCount = async (payload) => {
    const { data } = await Api.get('/api/writer/online-count', payload)
    return data
}
