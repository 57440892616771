import Api from '@/helpers/api/index.js'
import { removeEmptyParamsFromRequest } from '@/helpers/utils/'

import {
    GET_PRICING,
    GET_LIST_WRITERS
} from './action-types.js'

import {
    SET_PRICING
} from './mutation-types.js'

export const actions = {
    async [GET_PRICING]({ commit }) {
        const { data } = await Api.get('/api/data/order')
        commit(SET_PRICING, data)
    },
    async [GET_LIST_WRITERS](ctx, payload) {
        const { data } = await Api.get('/api/writer/list-by-params', removeEmptyParamsFromRequest({ ...payload }))
        return data
    }
};
