<template>
    <div
        v-click-outside="handleBlur"
        class="form-select form-select-country"
    >
        <fieldset class="form-select__fieldset form-select-country__fieldset">
            <legend class="form-select__legend">
                {{ label }}
            </legend>
            <input
                ref="code"
                autocomplete="off"
                class="form-select__input"
                :class="{ 'error-border': validationError, 'success': isValid, 'empty' : !country.value }"
                type="text"
                @click="openListItem = !openListItem; countryList = items"
                @focus="$event.target.select()"
                @input="onInputChanged($event)"
            >
            <span
                v-if="country.text"
                id="phone-code"
                class="phone-code"
                @click="openListItem = !openListItem"
            >
                <span :class="'flag-icon flag-input-icon flag-icon-' + country.iso.toLowerCase()" />
                {{ country.text }}
            </span>
            <!-- dropdown select -->
            <ul
                v-if="openListItem"
                class="form-select__list"
            >
                <li
                    v-for="(item, index) in countryList"
                    :key="index"
                    class="form-select__list-item"
                    @click="selectItem(item)"
                >
                    <div :class="'flag-icon flag-icon-' + item.iso.toLowerCase()" />
                    {{ item.text }}
                </li>
            </ul>
            <!-- dropdown select -->
        </fieldset>
        <span>{{ validationError }}</span>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
    name: 'FormSelectCountry',
    directives: {
        ClickOutside
    },
    props: {
        // eslint-disable-next-line vue/require-prop-types
        value: {},
        iconName: {
            type: String
        },
        items: {
            type: Array
        },
        label: {
            type: String
        },
        errors: {
            type: String
        },
        isValid: {
            type: Boolean
        },
        // eslint-disable-next-line vue/require-prop-types
        country: {}
    },
    data() {
        return {
            openListItem: false,
            searchItemName: null,
            filteredItems: [],
            search: '',
            initValue: null,
            countryList: [],
            countryIso: ''
        }
    },
    computed: {
        validationError() {
            return this.errors ? this.errors : ''
        }
    },
    mounted() {
        this.countryList = this.items
    },
    methods: {
        async selectItem(value) {
            // this.country = value
            await this.$emit('input', value)
            this.initValue = this.value
            this.openListItem = false
            this.search = ''
            this.$refs.code.value = ''
            this.countryIso = value.iso
        },
        onInputChanged(event) {
            this.openListItem = true
            this.search = event.target.value
            this.initValue = event.target.value
            if (!this.search) {
                this.countryList = this.items
                return
            }
            if (this.search.indexOf('+') === 0) {
                this.search = this.search.slice(1)
            }
            const countriesArray = this.items.filter((item) => (item.text.toLowerCase() + item.value.toString().toLowerCase()).includes(this.search.toLowerCase()))
            this.countryList = countriesArray.sort(this.sortByField('value'))
        },
        onChange(event) {
            this.$emit('update:field', event.target.value)
        },
        handleBlur() {
            if (this.openListItem) {
                this.openListItem = false
            }
        },
        sortByField(field) {
            return (a, b) => {
                if (a[field] > b[field]) {
                    return 1
                }
                return -1
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.form-select {
    outline: none;
    border: none;
    border-radius: $input-border-radius;
    max-height: 50px;
    width: 100%;
    &__icon {
        position: absolute;
        top: 10px;
        left: 12px;
    }
    &__fieldset {
        position: relative;
        border: none;
        outline: none;
    }
    &__legend {
        display: table;
        color: #b3bbc6;
        caret-color: #b3bbc6;
        padding: 2px 5px 2px 5px;
        font-size: 80%;
        position: absolute;
        left: 6px;
        top: -10px;
        background-color: white;
        border: none;
    }
    &__input {
        box-sizing: border-box;
        display: inline-block;
        // padding-left: 69%;
        width: 100%;
        cursor: pointer;
        line-height: 50px;
        outline: none;
        border-radius: $input-border-radius;
        font-family: $font-family-base, sans-serif;
        max-height: 48px;
        &.empty {
            padding-left: 10px;
        }
    }
    &__error {
        display: inline;
        width: auto;
        text-align: left;
        color: $error-color;
        caret-color: $error-color;
        font-size: 12px;
        min-height: 14px;
        position: relative;
        position: absolute;
        left: 13px;
        bottom: -7px;
        background: white;
    }
    &__list {
        overflow-y: scroll;
        max-height: 270px;
        background-color: white;
        position: absolute;
        z-index: 10;
        top: 55px;
        text-align: left;
        width: 100%;
        margin: 0;
        padding: 0;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
            0 3px 14px 2px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
    }
    &__list-item {
        display: flex;
        flex-direction: row;
        padding: 15px;
        background-color: $white;
        margin-bottom: 1px;
        cursor: pointer;
        &:hover {
            background-color: lightgrey;
        }
        .flag-icon {
            margin-right: 10px;
        }
    }
    .phone-code {
        position: absolute;
        cursor: pointer;
        font-size: 14px;
        top: 17px;
        left: 10px;
    }
}
</style>
