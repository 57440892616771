/* eslint-disable class-methods-use-this */
import moment from 'moment'

class Auth {
    constructor() {
        this.auth = {}
    }

    login(data) {
        const clientData = {
            token: data.token || data.access_token,
            refresh_token: data.refresh_token,
            expires_in: data.expires_in,
            expires_date: moment().add(data.expires_in, 'seconds')
        }
        window.localStorage.setItem('auth', JSON.stringify(clientData))
    }

    logout() {
        window.localStorage.removeItem('auth')
    }

    logoutVuex() {
        window.localStorage.removeItem('vuex')
    }

    isLogged() {
        if (!this.getToken()) {
            return false
        }
        if (!this.isTokenValid()) {
            return false
        }
        return true
    }

    userAuth() {
        if (!this.getToken() || this.getToken() === null) {
            return false
        }
        if (!this.isTokenValid()) {
            return false
        }
        return true
    }

    getLocalStorageAuth() {
        const auth = window.localStorage.getItem('auth')
        if (!auth) return false
        return JSON.parse(auth)
    }

    getLocalStorageVuex() {
        const persistentState = window.localStorage.getItem('vuex')
        if (!persistentState) return false
        return JSON.parse(persistentState)
    }

    getToken() {
        const { token } = this.getLocalStorageAuth()
        return token
    }

    getRefreshToken() {
        const { refresh_token } = this.getLocalStorageAuth()
        return refresh_token
    }

    getTokenExpiry() {
        // returns a date string in UTC
        const { expires_date } = this.getLocalStorageAuth()
        return expires_date
    }

    isTokenValid() {
        // !!!!  we go by UTC timezone !!!!!!
        // this means that the backend must return a date in ISO and UTC
        const token = moment.parseZone(this.getTokenExpiry()).valueOf()
        const now = moment().valueOf()
        return now < token
    }

    shouldDisplayPopup() {
        const delayInSeconds = Number(process.env.VUE_APP_AUTH_POPUP_TIME_TO_SHOW || '10') * 60
        const momentToken = moment(this.getTokenExpiry())
        const momentNow = moment().toISOString()
        const countdown = momentToken.diff(momentNow, 'seconds')
        if (countdown <= 0) throw new Error('Token is expired')
        return (countdown <= delayInSeconds)
    }

    getTimeToExpiryInSeconds() {
        const momentToken = moment(this.getTokenExpiry())
        const momentNow = moment().toISOString()
        return momentToken.diff(momentNow, 'seconds')
    }
}
export default new Auth()
