import { createNamespacedHelpers, mapGetters } from 'vuex'
import { eventBus } from '@/helpers/event-bus/'

import Auth from '@/helpers/auth'

import {
    ACTION_CLIENT_TOUCH,
    ACTION_GET_UNREAD_COUNTERS,
    ACTION_LOGOUT_CLIENT
} from '@/store/modules/client/action-types.js'

const {
    mapActions: mapClientActions
} = createNamespacedHelpers('client')

// this mixin contains the logic for session expiry
export default {
    data: () => ({
        authTimer: null,
        touchIntervalId: null
    }),
    computed: {
        ...mapGetters('client', ['getterIsAuth'])
    },
    watch: {
        async getterIsAuth(newVal) {
            if (newVal) {
                await this[ACTION_CLIENT_TOUCH]()
                await this[ACTION_GET_UNREAD_COUNTERS]()
                this.startAuthTimer()
            } else {
                clearInterval(this.authTimer)
                clearInterval(this.touchIntervalId)
            }
        }
    },
    async created() {
        // set timer for authentication popup (extend token expiry)
        if (this.getterIsAuth) {
            this.startAuthTimer()
            await this[ACTION_CLIENT_TOUCH]()
            await this[ACTION_GET_UNREAD_COUNTERS]()
            await this.touchInterval()
        } else {
            clearInterval(this.authTimer)
            clearInterval(this.touchIntervalId)
        }
    },
    methods: {
        ...mapClientActions([
            ACTION_CLIENT_TOUCH,
            ACTION_GET_UNREAD_COUNTERS,
            ACTION_LOGOUT_CLIENT
        ]),
        startAuthTimer() {
            if (window.location.pathname.includes('support/chat')) {
                clearInterval(this.authTimer)
            } else {
                this.authTimer = setInterval(async () => {
                    try {
                        const result = Auth.shouldDisplayPopup()
                        if (result) {
                            eventBus.$emit('showAuthPopup', Auth.getTimeToExpiryInSeconds())
                        }
                    } catch (error) {
                        eventBus.$emit(
                            'showSnackBar',
                            'Looks like your session has expired and you need to log in.',
                            'error',
                            true
                        )

                        // Clear client data
                        await this[ACTION_LOGOUT_CLIENT]()
                        eventBus.$emit('resetPopupFlag')
                        this.$router.push({ name: 'login' })
                        clearInterval(this.authTimer)
                    }
                }, 5000)
            }
        },
        async touchInterval() {
            this.touchIntervalId = setInterval(async () => {
                await this[ACTION_CLIENT_TOUCH]()
            }, 10 * 6 * 1000 * (process.env.VUE_APP_TOUCH_DELAY_MINUTES || 1));
        }
    }
}
