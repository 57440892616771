import {
    required,
    requiredIf,
    minLength,
    maxLength,
    email
} from 'vuelidate/lib/validators';

export const validationRules = {
    linked_to_order: {
        required: requiredIf(function () {
            return !!((this.clientIsAuth && this.chk_linked))
        })
    },
    verified: {
        isVerifiedPhone(value) {
            // here value is the verified value from vuex phone_verification object
            if (this.clientIsAuth) return true
            if (this.chk_phone_bypass) return true
            return value
        }
    },
    firstname: {
        maxLength: maxLength(64)
    },
    lastname: {
        maxLength: maxLength(64)
    },
    email: {
        required: requiredIf(function () {
            return !this.clientIsAuth
        }),
        email
    },
    type: { required },
    problem: {
        required: requiredIf(function () {
            if (this.type === 'complaint') {
                return true
            }
            return false
        })
    },
    resolution: {
        required: requiredIf(function () {
            if (this.type === 'complaint') {
                return true
            }
            return false
        })
    },
    subject: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(64)
    },
    message: {
        required,
        minLength: minLength(20),
        maxLength: maxLength(2048)
    }
}
