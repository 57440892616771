import {
    ACTION_SHOW_MESSENGER
} from './action-types.js'

import {
    SET_SHOW_MESSENGER
} from './mutation-types.js'

export const actions = {
    [ACTION_SHOW_MESSENGER](store, value) {
        store.commit(SET_SHOW_MESSENGER, value)
    }
};
