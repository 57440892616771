import { cloneDeep } from 'lodash'
import { updateField } from 'vuex-map-fields'

import {
    RESET,
    SET_CART_FROM_BACKEND,
    REMOVE_FROM_CART,
    SET_CART_CALCULATION,
    SET_CART_LOADING,
    SET_PAYMENT_ERROR,
    SET_BITFINEX_RESULT
} from './mutation-types.js'

import { initialState } from './state.js'

export const mutations = {
    updateField,
    [RESET](state) {
        Object.assign(state, cloneDeep(initialState))
    },
    [SET_CART_FROM_BACKEND](state, payload) {
        state.orders = payload
    },
    [SET_CART_CALCULATION](state, payload) {
        const payable = payload.total - payload.account_balance
        state.payment = {
            isLoading: false,
            account_balance: payload.account_balance,
            total: payload.total,
            payable: payable <= 0 ? 0 : payable,
            total_store_credit: payable <= 0 ? payload.total : payload.account_balance,
            orders: payload.orders
        }
    },
    [SET_CART_LOADING](state) {
        state.payment.isLoading = true
    },
    [REMOVE_FROM_CART](state, payload) {
        state.orders = state.orders.filter((item) => item.orderid !== payload.orderid)
    },
    [SET_PAYMENT_ERROR](state, payload) {
        state.payment_error = payload
    },
    [SET_BITFINEX_RESULT](state, payload) {
        state.bitfinex = payload
    }
}
