import FormSelect from '@/components/common/form-elements/FormSelect.vue'
import FormSwitch from '@/components/common/form-elements/FormSwitch.vue'
import FormInput from '@/components/common/form-elements/FormInput.vue'
import FormInputTypeNumber from '@/components/common/form-elements/FormInputTypeNumber.vue'
import FormTextarea from '@/components/common/form-elements/FormTextarea'
import FormCheckbox from '@/components/common/form-elements/FormCheckbox.vue'
import FormDatePicker from '@/components/common/form-elements/FormDatePicker.vue'
import FormSelectWriters from '@/components/common/form-elements/FormSelectWriters'
import FormSelectCountry from '@/components/common/form-elements/FormSelectCountry'
import FormSelectProject from '@/components/common/form-elements/FormSelectProject'
import FormMaskedInput from '@/components/common/form-elements/FormMaskedInput'
import FormRadioGroup from '@/components/common/form-elements/FormRadioGroup';
import FormRadio from '@/components/common/form-elements/FormInputRadio';
import FormFilesUploader from '@/components/common/form-elements/FormFilesUploader.vue'
import CustomButton from '@/components/common/CustomButton';
import FormSelectCountries from '@/components/common/form-elements/FormSelectCountries';
import FormSelectRegion from '@/components/common/form-elements/FormSelectRegion';
import ProgressLinear from '@/components/common/ProgressLinear';
import Hint from '@/components/common/Hint';
import DoubleFormElement from '@/components/common/form-elements/DoubleFormElement'
import FormSelectSort from '@/components/common/form-elements/FormSelectSort.vue'

const FormElements = {
    install(Vue) {
        Vue.component(FormSelect.name, FormSelect)
        Vue.component(FormSwitch.name, FormSwitch)
        Vue.component(FormInput.name, FormInput)
        Vue.component(FormInputTypeNumber.name, FormInputTypeNumber)
        Vue.component(FormTextarea.name, FormTextarea)
        Vue.component(FormCheckbox.name, FormCheckbox)
        Vue.component(FormDatePicker.name, FormDatePicker)
        Vue.component(FormSelectWriters.name, FormSelectWriters)
        Vue.component(FormSelectCountry.name, FormSelectCountry)
        Vue.component(FormMaskedInput.name, FormMaskedInput)
        Vue.component(FormSelectProject.name, FormSelectProject)
        Vue.component(FormRadioGroup.name, FormRadioGroup)
        Vue.component(FormRadio.name, FormRadio)
        Vue.component(CustomButton.name, CustomButton)
        Vue.component(FormSelectCountries.name, FormSelectCountries)
        Vue.component(FormSelectRegion.name, FormSelectRegion)
        Vue.component(ProgressLinear.name, ProgressLinear)
        Vue.component(Hint.name, Hint)
        Vue.component(DoubleFormElement.name, DoubleFormElement)
        Vue.component(FormSelectSort.name, FormSelectSort)
        Vue.component(FormFilesUploader.name, FormFilesUploader)
    }
}

export default FormElements
