import { getField } from 'vuex-map-fields'

export const getters = {
    getField,
    isPhoneVerified(state) {
        return state.verified
    },
    getPhoneObject(state) {
        const { form_data } = state
        return {
            phone: form_data.phone_number,
            phone_code: `${form_data.country.value}`,
            country: `${form_data.country.iso}`
        }
    }
}
