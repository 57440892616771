export const RESET = 'RESET'

export const SET_CART_FROM_BACKEND = 'SET_CART_FROM_BACKEND'

export const SET_CART_CALCULATION = 'SET_CART_CALCULATION'

export const SET_CART_LOADING = 'SET_CART_LOADING'

export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'

export const SET_PAYMENT_ERROR = 'SET_PAYMENT_ERROR'

export const SET_BITFINEX_RESULT = 'SET_BITFINEX_RESULT'
