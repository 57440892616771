import { createHelpers } from 'vuex-map-fields'

import { state } from './state.js';
import { getters } from './getters.js'
import { mutations } from './mutations.js'
import { actions } from './actions.js'

export const {
    mapFields: mapClientFormFields,
    mapFields: mapCreditCardFields
} = createHelpers({
    getterType: 'checkout/getField',
    mutationType: 'checkout/updateField'
})

export const checkout = {
    namespaced: true,
    state() {
        return { ...state }
    },
    mutations,
    actions,
    getters
}
