import {
    SET_LANG_ACTION
} from './action-types.js'

import {
    SET_LANG
} from './mutation-types.js'

export const actions = {
    [SET_LANG_ACTION](store, lang) {
        store.commit(SET_LANG, lang)
    }
};
