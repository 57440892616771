<template>
    <svg
        width="19"
        height="14"
        viewBox="0 0 19 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.7087 1L5.6254 13.0954L1.03027 8.5"
            stroke="#27D91F"
            stroke-width="1.8"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    name: 'SuccessIcon'
}
</script>
