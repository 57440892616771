import { getField } from 'vuex-map-fields'

export const getters = {
    getField,
    isEmailVerified(state) {
        return state.verified
    },
    getEmailObject(state) {
        const { form_data } = state
        return {
            email: form_data.email
        }
    }
}
