import {
    SET_LANG
} from './mutation-types.js';

export const mutations = {
    [SET_LANG](state, locale) {
        if (state.locales.includes(locale)) {
            state.locale = locale
        }
    }
}
