const Unsubscribe = () => import(/* webpackChunkName: "writers" */ '@/views/unsubscribe/unsubscribe.vue')

export default [
    {
        path: '/unsubscribe/:token',
        component: Unsubscribe,
        name: 'Unsubscribe',
        meta: {
            layout: 'EmptyLayout'
        }
    }
]
