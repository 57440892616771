export const ACTION_SET_ACTIVE_STEP = 'ACTION_SET_ACTIVE_STEP'

export const ACTION_SET_FIRST_ORDER_STEP = 'ACTION_SET_FIRST_ORDER_STEP'

export const ACTION_EDIT_ORDER = 'ACTION_EDIT_ORDER'

export const ACTION_NEW_ORDER = 'ACTION_NEW_ORDER'

export const ACTION_VALIDATE_DISCOUNT = 'VALIDATE_DISCOUNT'

export const ACTION_CALCULATE_ORDER = 'ACTION_CALCULATE_ORDER'

export const ACTION_UPLOAD_FILES = 'ACTION_UPLOAD_FILES'
