<template>
    <div
        class="breadcrumbs_layout"
    >
        <div class="header-menu">
            <main-header />
        </div>
        <Sidebar />
        <div class="wrapper main-section ">
            <header-top-info />
            <transition
                name="fade"
                mode="out-in"
                @after-leave="$root.$emit('triggerScroll')"
            >
                <keep-alive v-if="$route.meta.keepAlive">
                    <router-view :key="$route.fullpath" />
                </keep-alive>
                <router-view
                    v-else
                    :key="$route.fullpath"
                    class="page-content"
                />
            </transition>
        </div>

        <slot name="modals" />
    </div>
</template>

<script>
import HeaderTopInfo from '@/components/HeaderTopInfo';
import MainHeader from '@/components/Header';
import Sidebar from '../components/Sidebar.vue';

export default {
    name: 'BreadcrumbsLayout',
    components: {
        HeaderTopInfo,
        Sidebar,
        MainHeader
    }
}
</script>

<style lang="scss">
    .breadcrumbs_layout{
        min-height: 100vh;
        min-height: 100dvh;
        display: flex;
        justify-content: center;
        padding-left: 250px;
        @include media768max {
            padding-left: 80px;
        }
        @include media480max {
            flex-direction: column;
            justify-content: flex-start !important;
            padding-left: 0 !important;
            .header__logo {
                justify-content: flex-start !important;
                width: 50%;
            }
        }
        #page-footer{
            margin-top: auto;
        }
        .header-menu {
            display: none;
            @include media480max {
                display: flex;
            }
        }
    }
</style>
