<template>
    <div class="avatar-wrapper without_skeleton">
        <div
            class="without_skeleton"
            :class="className"
        >
            <div
                v-if="avatarUrl"
                class="avatar-img"
                :style="size ? { width: size + 'px', height: size + 'px'} : ''"
                :class="user"
            >
                <img
                    :src="avatarUrl"
                    alt="avatar"
                    class="avatar-user"
                >
            </div>
            <div
                v-else-if="avatarFallback"
                class="avatar-img"
                :style="size ? { width: size + 'px', height: size + 'px'} : ''"
                :class="user"
            >
                <img
                    :src="`/img/avatars/${avatarFallback}`"
                    alt="avatar"
                    class="avatar-user"
                >
            </div>
            <div
                v-else
                class="avatar-img"
                :style="size ? { width: size + 'px', height: size + 'px'} : ''"
                :class="user"
            >
                <span
                    v-if="getInitials"
                    class="initials"
                >
                    {{ getInitials }}
                </span>
                <div
                    v-else
                    class="default-avatar"
                >
                    <span class="default-avatar__img" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        avatarUrl: {
            type: String,
            required: false
        },
        avatarFallback: {
            type: String,
            required: false
        },
        name: {
            type: String
        },
        className: {
            type: String,
            required: false,
            default: 'avatar-person'
        },
        size: {
            type: Number,
            required: false
        },
        user: {
            type: String
        }
    },
    computed: {
        getInitials() {
            if (this.name) {
                return this.name[0] + this.name[1]
            }
            return ''
        }
    }
}
</script>
<style lang="scss">
    .avatar {
        &-person, &-list {
            height: 100%;
        }

        &-img {
            background-color: $main-color;
            color: $white;
            border-radius: 50% !important;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
            &.writer {
                //background: rgba($main-color, 0.1);
                .initials {
                    color: $white;
                }
            }
            &.admin {
                background: rgba(155, 81, 224, 0.1);
                .initials {
                    color: #9B51E0;
                }
                .default-avatar {
                    background: #9B51E0;
                }
            }
            .initials {
                font-size: 1em;
                font-weight: bold;
                text-transform: uppercase;
            }
            .default-avatar {
                mask-image: url('~@/assets/img/svg/default-avatar.svg');
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                background: #fff;
                width: 20px;
                height: 21px;
            }
        }
    }

    .report .avatar-img {
        width: 25px;
        height: 25px;
    }

</style>
