import Api from '@/helpers/api/index.js'

export const uploadFiles = async (filesArr, orderid, callback) => {
    const formData = new FormData();
    filesArr.forEach((file) => {
        formData.append(`uploaded[${file.name}]`, file)
    });
    if (orderid) {
        formData.append('orderid', orderid);
    }
    const { data } = await Api.post('/api/file/additional/upload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: callback
    })
    return data
}

export const uploadFilesNoAuth = async (filesArr, orderid, callback) => {
    const formData = new FormData();
    filesArr.forEach((file) => {
        formData.append(`uploaded[${file.name}]`, file)
    });
    if (orderid) {
        formData.append('orderid', orderid);
    }
    const { data } = await Api.post('/api/file/additional/upload-no-auth', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: callback
    })
    return data
}

export const deleteFile = async (id) => {
    await Api.delete('/api/file/additional/delete', { id })
}

export const deleteFileNoAuth = async (id) => {
    await Api.delete('/api/file/additional/delete-no-auth', { id })
}

export const additionalMarkAsRead = async (id) => {
    await Api.put('/api/file/additional/mark-as-read', { id })
}

export const mainMarkAsRead = async (id) => {
    await Api.put('/api/file/main/mark-as-read', { id })
}
