import { createHelpers } from 'vuex-map-fields'

import { state } from './state.js'
import { getters } from './getters.js'
import { mutations } from './mutations.js'
import { actions } from './actions.js'

export const { mapFields: mapSupportFields } = createHelpers({
    getterType: 'support/getField',
    mutationType: 'support/updateField'
});

export const support = {
    namespaced: true,
    state() {
        return { ...state }
    },
    mutations,
    actions,
    getters
};
