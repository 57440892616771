<template>
    <div>
        <div
            v-if="showMenu"
            class="modal-blur"
        />
        <div
            class="sidebar"
            :class="showMenu ? 'sidebar-mobile--active' : ''"
        >
            <div class="sidebar-header">
                <div
                    class="header__logo"
                    style="margin: 5px"
                >
                    <a
                        :href="'https://' + getAppDomain"
                        class="header__logo-link header__logo-img"
                    />
                </div>
                <div
                    class="sidebar-burger"
                    @click="showMenu = !showMenu"
                >
                    <MenuOpen v-if="showMenu" />
                    <MenuClose v-else />
                </div>
            </div>
            <div class="sidebar-order__btn">
                <order-cart-button ref="orderBtn" />
                <tooltip-lg
                    v-if="showOrderTooltip"
                    :target="orderBtn"
                    :update-position="showMenu"
                    placement="right"
                    position="fixed"
                    closable
                    animate
                >
                    <div class="flex flex-col gap-4">
                        <p><strong>👋 Start here!</strong></p>
                        <p>Press button <strong> &laquo;NEW ORDER&raquo;</strong> to create your first order.</p>
                    </div>
                </tooltip-lg>
            </div>
            <div class="sidebar-profile__section">
                <div
                    class="sidebar-menu__item"
                    :class="$route.path === '/settings' ? 'sidebar-menu__item--active' : ''"
                >
                    <div class="sidebar-menu__item--between">
                        <div style="display: flex; align-items: center;">
                            <avatar
                                :size="30"
                                :name="getInitial"
                            />
                            <p class="sidebar-menu__item-text sidebar-menu__item-profile">
                                {{ getClientName }}
                            </p>
                        </div>
                        <chevron-right-icon />
                    </div>
                    <div class="sidebar-menu__item-submenu">
                        <router-link
                            :to="{ name: 'profile' }"
                        >
                            <div
                                class="sidebar-menu__item"
                                :class="$route.path === '/settings' ? 'sidebar-menu__item--active' : ''"
                            >
                                <div class="sidebar-icon--sidebar sidebar-icon__profile" />
                                <div class="sidebar-menu__item-text">
                                    Settings
                                </div>
                            </div>
                        </router-link>
                        <a
                            href="#"
                            class="sidebar-menu__item"
                            @click.prevent="logout()"
                        >
                            <span class="sidebar-icon--sidebar sidebar-icon__logout" />
                            <span class="sidebar-menu__item-text">
                                Sign Out
                            </span>
                        </a>
                    </div>
                </div>
                <div class="sidebar-balances">
                    <div
                        class="sidebar-balances__item"
                    >
                        <div style="display:flex; flex-direction: column; gap: 5px">
                            <span
                                class="tooltip-zoom tooltip-zoom--left"
                                style="display:flex; align-items: center"
                                :data-tooltip-attribute="`Your balance: Top up and pay orders faster.`"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                >
                                    <path d="M452 78.668h-18.668V60c0-33.086-26.914-60-60-60h-314C26.617 0 0 26.617 0 59.332V449c0 34.738 28.262 63 63 63h389c33.086 0 60-26.914 60-60V138.668c0-33.086-26.914-60-60-60zm20 255.664h-78.332c-21.508 0-39-17.492-39-39 0-21.504 17.492-39 39-39H472zM59.332 40h314c11.027 0 20 8.973 20 20v18.668h-334C48.672 78.668 40 69.992 40 59.332 40 48.672 48.672 40 59.332 40zM452 472H63c-12.684 0-23-10.316-23-23V115.434a59.112 59.112 0 0 0 19.332 3.23H452c11.027 0 20 8.973 20 20v77.668h-78.332c-43.563 0-79 35.441-79 79 0 43.563 35.437 79 79 79H472V452c0 11.027-8.973 20-20 20zm0 0" />
                                </svg>
                                <b style="font-size: 14px"> {{ getterMainClientInfo.account_balance | money }}</b>
                            </span>
                            <span
                                class="load-funds"
                                @click="showTopUp=true"
                            >
                                Load funds
                            </span>
                        </div>
                        <TopUpModal
                            v-if="showTopUp"
                            @closeTopUp="showTopUp = false"
                        />
                    </div>
                    <div class="sidebar-balances__item">
                        <span
                            class="tooltip-zoom tooltip-zoom--left"
                            style="display:flex; align-items: center"
                            :data-tooltip-attribute="`Your loyalty discount. Applied automatically to all orders.`"
                        >
                            <svg
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M21.41 11.58L12.41 2.58C12.05 2.22 11.55 2 11 2H4C2.9 2 2 2.9 2 4V11C2 11.55 2.22 12.05 2.59 12.42L11.59 21.42C11.95 21.78 12.45 22 13 22C13.55 22 14.05 21.78 14.41 21.41L21.41 14.41C21.78 14.05 22 13.55 22 13C22 12.45 21.77 11.94 21.41 11.58ZM13 20.01L4 11V4H11V3.99L20 12.99L13 20.01Z"
                                />
                                <path
                                    d="M6.5 8C7.32843 8 8 7.32843 8 6.5C8 5.67157 7.32843 5 6.5 5C5.67157 5 5 5.67157 5 6.5C5 7.32843 5.67157 8 6.5 8Z"
                                />
                                <path
                                    d="M8.8999 12.55C8.8999 13.12 9.1299 13.62 9.4999 14L12.9999 17.5L16.4999 14C16.8544 13.6096 17.045 13.0977 17.0323 12.5705C17.0195 12.0433 16.8044 11.5412 16.4316 11.1683C16.0587 10.7955 15.5566 10.5804 15.0294 10.5676C14.5022 10.5549 13.9903 10.7455 13.5999 11.1L12.9999 11.7L12.3999 11.11C12.0299 10.73 11.5099 10.5 10.9499 10.5C9.8199 10.5 8.8999 11.42 8.8999 12.55Z"
                                />
                            </svg>
                            <b style="font-size: 14px"> {{ getterMainClientInfo.loaylty_discount | percent }}</b>
                        </span>
                    </div>
                </div>
            </div>
            <div class="sidebar-menu__section">
                <router-link :to="{ name: 'notifications' }">
                    <div
                        class="sidebar-menu__item sidebar-menu__item-notifications"
                        :class="$route.path === '/notifications' ? 'sidebar-menu__item--active' : ''"
                    >
                        <div style="display: flex; align-items: center  ;">
                            <div class="notification-bell-wrapper">
                                <div
                                    style="position: relative;"
                                    :class="countUnread > 0 ? 'bounce-bell' : ''"
                                >
                                    <Bell
                                        class="alert_btn__icon stroke-icon "
                                        color="black"
                                    />
                                    <span
                                        v-if="countUnread > 0"
                                        class="alert__bubble"
                                    >
                                        {{ countUnread }}
                                    </span>
                                </div>
                            </div>
                            <p class="sidebar-menu__item-text sidebar-menu__item-text-title">
                                Notifications
                            </p>
                        </div>
                    </div>
                </router-link>
                <router-link
                    v-for="(item, index) in menu"
                    :key="index"
                    :to="{ name: item.route_name }"
                >
                    <div
                        class="sidebar-menu__item"
                        :class="$route.path === item.path ? 'sidebar-menu__item--active' : ''"
                    >
                        <div
                            class="sidebar-icon"
                            :class="`sidebar-icon__${item.icon}`"
                        />
                        <div class="sidebar-menu__item-text sidebar-menu__item-text-title">
                            <p>{{ item.title }}</p>
                            <span v-if="item.title === 'Writers'">
                                <span v-if="getterWriterOnline && getterWriterOnline > 0">{{ getterWriterOnline }} online</span>
                            </span>
                        </div>
                    </div>
                </router-link>
                <!-- <support-messenger-button
                    v-if="chatVisible"
                    :show-messenger="false"
                    @click="setShowMessenger(!getterShowMessenger)"
                /> -->
            </div>
            <button
                v-if="chatVisible"
                class="livechat"
                @click="setShowMessenger(!getterShowMessenger)"
            >
                <div
                    class="sidebar-menu__item-notifications"
                >
                    <div style="display: flex; align-items: center  ;">
                        <div
                            style="position: relative; width: 40px;height: 40px;"
                        >
                            <img
                                :src="`/img/operators/${operatorImage}.jpg`"
                                alt="support agent"
                                class="support-messenger__image"
                                style="width:40px;height:40px;"
                            >
                        </div>
                        <p
                            class="sidebar-menu__item-text sidebar-menu__item-text-title"
                            style="margin-left: 10px"
                        >
                            Live chat
                        </p>
                    </div>
                </div>
            </button>
        </div>
    </div>
</template>

<script>
import TooltipLg from '@/components/TooltipLg.vue'
import OrderCartButton from '@/components/Header/OrderCartButton.vue'
import Avatar from '@/components/common/Avatar'
import ChevronRightIcon from 'mdi-vue/ChevronRight'
import MenuOpen from 'mdi-vue/MenuOpen'
import MenuClose from '@/components/icons/MenuClose'
import Bell from '@/components/icons/Bell'
// import WalletPlusOutline from 'mdi-vue/WalletPlusOutline'
import TopUpModal from '@/components/account/TopUpModal';

import Api from '@/helpers/api/index.js'
import SidebarMenu from '@/constants/sidebarMenu.js'
import filtersMixin from '@/mixins/filtersMixin.js'
import Helper from '@/helpers/functions'
import { eventBus } from '@/helpers/event-bus/'
import { mapGetters, createNamespacedHelpers } from 'vuex'
import {
    ACTION_LOGOUT_CLIENT, ACTION_UPDATE_CLIENT_DATA
} from '@/store/modules/client/action-types'

import {
    ACTION_SHOW_MESSENGER
} from '@/store/modules/messenger/action-types.js'

import store from '../store';

const {
    mapActions: mapClientActions
} = createNamespacedHelpers('client')

const {
    mapActions: mapMessengerActions
} = createNamespacedHelpers('messenger')

export default {
    components: {
        TooltipLg,
        OrderCartButton,
        Avatar,
        ChevronRightIcon,
        Bell,
        // WalletPlusOutline,
        TopUpModal,
        MenuOpen,
        MenuClose
    },
    mixins: [filtersMixin],
    data() {
        return {
            orderBtn: null,
            showOrderTooltip: false,
            showTopUp: false,
            countUnread: 0,
            showMenu: false,
            chatVisible: false
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterMainClientInfo',
            'getReferralId',
            'getterWriterOnline'
        ]),
        ...mapGetters('checkout', [
            'getterPayment'
        ]),
        ...mapGetters('messenger', [
            'getterShowMessenger'
        ]),
        getAppDomain() {
            return process.env.VUE_APP_DOMAIN;
        },
        getInitial() {
            const { firstname, lastname } = this.getterMainClientInfo
            if (!!firstname && !!lastname) {
                return `${firstname[0]}${lastname[0]}`
            }
            return null
        },
        getClientName() {
            const { firstname, id } = this.getterMainClientInfo
            if (firstname) {
                return firstname.charAt(0).toUpperCase() + firstname.slice(1)
            }
            return `User ${id}`
        },
        menu() {
            return SidebarMenu
        },
        referalLink() {
            return `shop.${process.env.VUE_APP_DOMAIN}/referral/${this.getReferralId}`
        },
        operatorImage() {
            return Math.floor(Math.random() * 7) + 1
        }
    },
    watch: {
        showTopUp(val) {
            Helper.lockBody(val)
        },
        '$route.name': function () {
            if (this.$route.name === 'notifications') {
                this.countUnread = 0
            }
        }
    },
    async mounted() {
        this.orderBtn = this.$refs.orderBtn
        const hasOrderTooltip = localStorage.getItem('order-tooltip')
        if (!hasOrderTooltip) {
            this.showOrderTooltip = true
            localStorage.setItem('order-tooltip', new Date().toISOString())
        }
        await this.getListUreadMsgs()
        await store.dispatch('client/ACTION_UPDATE_CLIENT_DATA')
        await store.dispatch('checkout/ACTION_GET_CART_FROM_BACKEND')
        this.chatVisible = Boolean(localStorage.getItem('chat-hidden'))
    },
    methods: {
        ...mapClientActions([
            ACTION_LOGOUT_CLIENT,
            ACTION_UPDATE_CLIENT_DATA
        ]),
        ...mapMessengerActions([
            ACTION_SHOW_MESSENGER
        ]),
        async logout() {
            try {
                await this[ACTION_LOGOUT_CLIENT]()
                this.$router.push({ name: 'login' })
            } catch (e) {
                console.error(e)
            }
        },
        copyToClickboard() {
            navigator.clipboard.writeText(this.referalLink)
            eventBus.$emit('showSnackBar', 'The link was copied!', 'success');
        },
        async getListUreadMsgs() {
            try {
                const { data } = await Api.get('/api/alert/list', {
                    page: 1,
                    per_page: 10
                })
                this.countUnread = data.counters.unread
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        setShowMessenger(val) {
            this[ACTION_SHOW_MESSENGER](val)
        }
    }
}
</script>

<style lang="scss">
    .sidebar {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        left: 0;
        background-color: #fafafa;
        height: 100vh;
        width: 250px;
        transition: .2s;
        z-index: 99;
        @include media768max {
            width: 80px;
        }
        @include media480max {
            display: none;
        }

        &-menu__item {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            position: relative;
            padding: 0 15px;
            transition: .1s;
            cursor: pointer;

            @include media768max {
                justify-content: center;
                padding: 0;
            }

            &-submenu {
                position: absolute;
                top: 0;
                left: 250px;
                width: 150px;
                background-color: #333333;
                opacity: 0;
                height: 0;
                visibility: hidden;
                z-index: -1;
                transition: .2s;

                .sidebar-menu__item-text, .sidebar-icon {
                    color: white !important;
                }
                .sidebar-icon {
                    background-color: white !important;
                }
                .sidebar-menu__item:hover {
                    .sidebar-menu__item-text {
                        color: $main-color !important;
                    }
                    .sidebar-icon {
                        background-color: $main-color !important;
                    }
                }
                @include media768max {
                    left: 80px;
                    .sidebar-menu__item-text {
                        display: block;
                    }
                }
            }

            &--between {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                @include media768max {
                    justify-content: center;
                    svg {
                        display: none;
                    }
                }
            }

            &-text {
                font-family: $font-family-base;
                font-size: 16px;
                font-weight: normal;
                color: $font-color-main;
                text-transform: capitalize;
                line-height: 1.6;
                transition: color 0.2s ease;
                position: relative;
                margin-left: 10px;
                @include media768max {
                    display: none;
                }
            }

            &-profile {
                margin-left: 10px;
            }

            &-notifications {
                .bounce-bell {
                    animation: bounce 1s infinite;
                    svg {
                        fill: $main-color;
                        path {
                            stroke: $main-color;
                        }
                    }
                }
                .alert__bubble {
                    position: absolute;
                    top: -10px;
                    right: -5px;
                    width: 18px;
                    height: 18px;
                    background: #1bb361;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    // margin: 0 7px;
                    font-size: 12px;
                    font-weight: 700;
                }
            }

            &--active {
                background: rgba($main-color, 0.1);
                border-right: 4px solid $main-color;
            }

            &:hover {
                background: rgba($main-color, 0.1);

                .sidebar-icon {
                    background: $main-color;
                }

                .sidebar-menu__item-text {
                    color: $main-color;
                }

                .sidebar-menu__item-submenu {
                    opacity: 1;
                    visibility: visible;
                    height: auto;
                    z-index: 9999;
                }

                svg {
                    fill: $main-color;
                }
                .stroke-icon {
                    fill: transparent;
                    path {
                        stroke: $main-color;
                    }
                }
            }
        }

        &-header {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 15px;
            @include media768max {
                margin: 15px 0;
                .header__logo {
                    display: none;
                }
            }
        }

        &-burger {
            display: none;
            width: 30px;
            height: 30px;
            border: 1px solid #000;
            border-radius: 4px;
            svg {
                width: 30px;
                height: 30px;
            }
            @include media768max {
                display: block;
            }
        }

        &-order__btn {
            position: relative;
            display: flex;
            justify-content: center;
            z-index: 99999999;
            margin-top: 10px;
        }

        &-balance__topup {
            display: flex;
            justify-content: center;
            margin-top: 10px;
            padding: 0 15px;
            .btn-border {
                width: 100%;
            }
            @include media768max {
                padding: 0;
                span:last-child {
                    display: none;
                }
                .btn-border {
                    width: calc(100% - 15px);
                    border-radius: 4px;
                    padding: 0;
                }
            }
        }

        &-profile__section {
            margin-top: 30px;
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            background-color: #f6f8ff;
        }
        &-menu__section {
            margin-top: 30px;
            margin-bottom: 30px;
        }
        &-icon {
            width: 26px;
            height: 30px;
            position: absolute;
            mask-size: auto;
            mask-repeat: no-repeat;
            mask-position: center;
            background: $font-color-main;

            &__orders {
                mask-image: url("~@/assets/img/svg/clipboard.svg");
                margin-left: -3px;
                @include media768max {
                    margin-left: 4px;
                }
            }

            &__billing {
                mask-image: url("~@/assets/img/svg/minus-square.svg");
                margin-left: -3px;
            }

            &__credits {
                mask-image: url("~@/assets/img/svg/dollar-sign.svg");
                margin-left: -3px;
            }

            &__tickets {
                mask-image: url("~@/assets/img/svg/phone.svg");
                margin: 0 -9px 0 -8px;
                mask-size: contain;
                width: 40px;
                height: 40px;
            }

            &__profile {
                mask-image: url("~@/assets/img/svg/settings.svg");
            }

            &__friends {
                mask-image: url("~@/assets/img/svg/users.svg");
                margin-right: -3px;
            }

            &__logout {
                mask-image: url("~@/assets/img/svg/log-out.svg");
            }

            &__pen {
                mask-image: url("~@/assets/img/svg/users.svg");
                margin-right: -3px;
            }
        }
        &-balances{
            display: flex;
            align-items: baseline;
            padding: 0 15px;
            margin: 10px 0;
            @include media768max {
                display: none;
            }
            &__item {
                display: flex;
                margin-right: 15px;
                align-items: center;
                color: #61646A;
                &:first-child{
                    width: 70%;
                    border-right: 1px solid #ccc;
                }
                &:last-child {
                    width: 30%;
                    margin-right: 0;
                }
                svg {
                    width: 23px;
                    fill: #111720;
                    margin-right: 5px;
                }
                & > b {
                    color: #111720;
                    margin-right: 5px;
                }
            }
        }
        &-referral {
            padding: 15px;
            margin-top: auto;
            &__link {
                input{
                    padding-right: 15px;
                }
                label {
                    color: $main-color;
                    background: #fafafa !important;
                }
                svg {
                    fill: $main-color;
                    animation: shake 3s linear infinite;
                }
            }
            .invite-details {
                display: none;
            }
            .invite-wrapper-form {
                flex-direction: column;
            }
            .invite_email {
                width: 100%;
                margin: 10px 0 0;
            }
            .invite_link_copy__btn {
                margin-left: 0;
                margin-top: 10px;
            }
        }
        .cart-btn-animated {
            transition: 0s;
        }
    }
    .sidebar.sidebar-mobile--active {
        width: 250px;
        .sidebar-header .header__logo {
            display: block;
        }
        .sidebar-burger {
            border-color: $main-color;
            svg {
                fill: $main-color;
            }
        }
        .cart-btn-order__cart {
            display: block;
        }
        .sidebar-balance__topup span:last-child {
            display: block;
        }
        .sidebar-balances {
            display: flex;
        }
        .sidebar-menu__item {
            justify-content: flex-start;
            padding: 0 15px;
            &--between {
                justify-content: space-between;
                svg {
                    display: block;
                }
            }
            &-text {
                display: block;
            }
            &-submenu {
                left: 250px;
            }
        }
        .cart-btn-order-text {
            display: block;
            margin-left: 10px;
        }
        .sidebar-icon__orders {
            margin-left: -3px;
        }
    }
    @keyframes shake {
        0% { transform: skewY(-15deg); }
        5% { transform: skewY(15deg); }
        10% { transform: skewY(-15deg); }
        15% { transform: skewY(15deg); }
        20% { transform: skewY(0deg); }
        100% { transform: skewY(0deg); }
    }
    @keyframes bounce {
        0%, 100% {
            transform: translateY(-10%);
            animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
        }
        50% {
            transform: translateY(0);
            animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
        }
    }

    .sidebar-menu__item-text-title{
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;

        margin-left: 50px;

        @include media768max {
            display: none;
        }
        span {
            color: #1FB119;
            font-weight: 800;
            font-size: 10px;
            text-transform: uppercase;
        }
    }
    .load-funds {
        color: rgb(7, 7, 108);
        font-size: 12px;
        cursor: pointer;
    }
    .notification-bell-wrapper{
        position: absolute;
        @include media768max {
            position: inherit;
        }
    }
    .sidebar-icon--sidebar{
        width: 26px;
        height: 30px;
        mask-size: auto;
        mask-repeat: no-repeat;
        mask-position: center;
        background: #fff;
    }
    .livechat {
        background-color: white;
        border-radius: 8px;
        border: 2px solid rgba($main-color, 0.9);
        margin: 0px 10px;
    }
</style>
