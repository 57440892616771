// vuex
import { createNamespacedHelpers, mapGetters } from 'vuex'
import {
    ACTION_UPDATE_CLIENT_DATA
} from '@/store/modules/client/action-types.js'
import {
    ACTION_GET_CART_FROM_BACKEND
} from '@/store/modules/checkout/action-types.js'

const {
    mapActions: mapClientActions
} = createNamespacedHelpers('client')
const {
    mapActions: mapCheckoutActions
} = createNamespacedHelpers('checkout')

export default {
    data() {
        return {
            isLoaded: false
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterMainClientInfo',
            'getterIsAuth'
        ])
    },
    watch: {
        getterIsAuth() {
            console.log('getter');
            this.$socket.reconnect()
        }
    },
    methods: {
        ...mapClientActions([
            ACTION_UPDATE_CLIENT_DATA
        ]),
        ...mapCheckoutActions([
            ACTION_GET_CART_FROM_BACKEND
        ])
    },
    async created() {
        if (this.getterIsAuth && !this.getterMainClientInfo.id) {
            // await this[ACTION_UPDATE_CLIENT_DATA]()
            await this[ACTION_GET_CART_FROM_BACKEND]()
        }
        this.$socket.connect()
        this.isLoaded = true
    }
}
