const Writers = () => import(/* webpackChunkName: "writers" */ '@/views/writers/Main.vue')
const WriterProfile = () => import(/* webpackChunkName: "writers" */ '@/views/writers/WriterProfile.vue')
const WriterTabAboutWriter = () => import('@/components/writers/WriterTabAboutWriter.vue')
const WriterTabReviews = () => import('@/components/writers/WriterTabReviews.vue')

export default [
    {
        path: '/writers',
        component: Writers,
        name: 'writers',
        meta: {
            keepAlive: true,
            layout: 'EmptyLayout',
            showHeader: true,
            requiresAuth: false,
            breadcrumbs: {
                name: 'Writers',
                additionalClass: 'bc-horizontal bc-writers',
                excerpt: 'Our vetted experts are standing by for your tasks!',
                imgSrc: '../../assets/img/svg/writers.svg'
            }
        }
    },
    {
        path: '/writers-account',
        component: Writers,
        name: 'writers-account',
        meta: {
            keepAlive: true,
            layout: 'BreadcrumbsLayout',
            showHeader: true,
            requiresAuth: true,
            breadcrumbs: {
                name: 'Writers',
                additionalClass: 'bc-horizontal',
                excerpt: 'Our vetted experts are standing by for your tasks!',
                imgSrc: '../../assets/img/svg/writers.svg'
            }
        }
    },
    {
        path: '/writers/profile/:id',
        component: WriterProfile,
        // name: 'writer-profile',
        meta: {
            keepAlive: true,
            layout: 'EmptyLayout',
            requiresAuth: false,
            breadcrumbs: {
                // name: 'Meet our professionals',
                additionalClass: 'bc-horizontal',
                parent_route_names: ['writers']
            }
        },
        children: [
            {
                path: '',
                name: 'writer_about',
                component: WriterTabAboutWriter,
                meta: {
                    scrollToTop: true,
                    keepAlive: true,
                    requiresAuth: false,
                    layout: 'EmptyLayout',
                    showHeader: true,
                    breadcrumbs: {
                        // name: 'About writer',
                        // layout: 'EmptyLayout',
                        name: 'About writer',
                        additionalClass: 'bc-horizontal bc-writer-profile',
                        parent_route_names: ['writers'],
                        hasBack: true
                    }
                }
            },
            {
                path: 'reviews',
                name: 'writer_reviews',
                component: WriterTabReviews,
                meta: {
                    scrollToTop: false,
                    keepAlive: true,
                    requiresAuth: false,
                    layout: 'EmptyLayout',
                    showHeader: true,
                    breadcrumbs: {
                        name: 'Reviews',
                        additionalClass: 'bc-horizontal bc-writer-profile',
                        parent_route_names: ['writers'],
                        hasBack: true
                    }
                }
            }
        ]
    },
    {
        path: '/writers-account/profile/:id',
        component: WriterProfile,
        // name: 'writer-profile',
        meta: {
            keepAlive: true,
            layout: 'BreadcrumbsLayout',
            requiresAuth: true,
            breadcrumbs: {
                // name: 'Meet our professionals',
                additionalClass: 'bc-horizontal',
                parent_route_names: ['writers-account']
            }
        },
        children: [
            {
                path: '',
                name: 'writer_account_about',
                component: WriterTabAboutWriter,
                meta: {
                    scrollToTop: true,
                    keepAlive: true,
                    requiresAuth: true,
                    layout: 'BreadcrumbsLayout',
                    showHeader: true,
                    breadcrumbs: {
                        // name: 'About writer',
                        // layout: 'EmptyLayout',
                        name: 'About writer',
                        additionalClass: 'bc-horizontal bc-writer-profile',
                        parent_route_names: ['writers-account'],
                        hasBack: true
                    }
                }
            },
            {
                path: 'reviews',
                name: 'writer_account_reviews',
                component: WriterTabReviews,
                meta: {
                    scrollToTop: false,
                    keepAlive: true,
                    requiresAuth: true,
                    layout: 'BreadcrumbsLayout',
                    showHeader: true,
                    breadcrumbs: {
                        name: 'Reviews',
                        additionalClass: 'bc-horizontal bc-writer-profile',
                        parent_route_names: ['writers-account'],
                        hasBack: true
                    }
                }
            }
        ]
    }
]
