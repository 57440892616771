export const ACTION_CREATE_BATCH = 'ACTION_CREATE_BATCH'

export const ACTION_WORLDPAY_GET_TOKEN = 'ACTION_WORLDPAY_GET_TOKEN'

export const ACTION_PAY_WITH_CARD_FIRST = 'ACTION_PAY_WITH_CARD_FIRST'

export const ACTION_PAY_WORLDPAY_350 = 'ACTION_PAY_WORLDPAY_350'

export const ACTION_PAY_WITH_CARD_SECOND = 'ACTION_PAY_WITH_CARD_SECOND'

export const ACTION_PAY_SUCCESS = 'ACTION_PAY_SUCCESS'

export const ACTION_GET_CART_FROM_BACKEND = 'ACTION_GET_CART_FROM_BACKEND'

export const ACTION_DELETE_ORDER = 'ACTION_DELETE_ORDER'

export const ACTION_BITFINEX_PAYMENT = 'ACTION_BITFINEX_PAYMENT'
