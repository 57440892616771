<template>
    <div
        class="breadcrumbs_layout"
        :class="{'background-wrapper': bgWrapper}"
    >
        <div class="header-menu">
            <main-header />
        </div>
        <Sidebar />
        <div class="wrapper main-section ">
            <header-top-info />

            <breadcrumbs
                :settings="settingsBreadcrumbs"
                :total-orders="totalOrders"
            />

            <transition
                name="fade"
                mode="out-in"
                @after-leave="$root.$emit('triggerScroll')"
            >
                <keep-alive v-if="$route.meta.keepAlive">
                    <router-view :key="$route.fullpath" />
                </keep-alive>
                <router-view
                    v-else
                    :key="$route.fullpath"
                    class="page-content"
                />
            </transition>
            <!--
        <main-footer /> -->
        </div>

        <slot name="modals" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MainHeader from '@/components/Header';
import HeaderTopInfo from '@/components/HeaderTopInfo';
import Breadcrumbs from '@/components/Breadcrumbs';
// import MainFooter from '@/components/Footer';

import { routes } from '@/router/routesAll'
import Sidebar from '../components/Sidebar.vue';

export default {
    name: 'BreadcrumbsLayout',
    components: {
        HeaderTopInfo,
        MainHeader,
        Breadcrumbs,
        // MainFooter
        Sidebar
    },
    data() {
        return {
            breadcrumbs: [
                {
                    title: 'Home',
                    url: '/'
                }
            ]
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterMainClientInfo',
            'getPurchases'
        ]),
        isOrderForm() {
            return this.$route.name.includes('order_new') || this.$route.name.includes('order_edit')
        },
        settingsBreadcrumbs() {
            if (this.$route.name === 'orders') {
                return this.ordersBreadCrumbsSettings
            }
            if (this.$route.name.includes('order_') && !this.isOrderForm && this.$route.name !== 'order_details_express') {
                return this.orderDetailsBreadCrumbsSettings
            }

            return {
                title: this.$route.meta.breadcrumbs.name,
                steps: [
                    ...this.breadcrumbs
                ],
                excerpt: this.$route.meta.breadcrumbs.excerpt,
                additionalClass: this.$route.meta.breadcrumbs.additionalClass
            }
        },
        bgWrapper() {
            return this.$route.meta.breadcrumbs.bgWrapper
        },
        totalOrders() {
            const { count } = this.$store.state.order.selectedPreset
            if (count === null && this.$route.name === 'orders') {
                return Number(this.getterMainClientInfo.order_total)
            }
            if (this.$route.name === 'orders') {
                return count
            }
            return 0
        },
        totalBatches() {
            if (this.$route.name === 'billing') {
                return Number(this.getPurchases)
            }
            return 0
        },
        ordersBreadCrumbsSettings() {
            const { selectedPreset } = this.$store.state.order
            return ({
                title: selectedPreset.title,
                steps: [
                    ...this.breadcrumbs
                ],
                excerpt: this.$route.meta.breadcrumbs.excerpt,
                additionalClass: this.$route.meta.breadcrumbs.additionalClass
            })
        },
        orderDetailsBreadCrumbsSettings() {
            const { orderDetailsTitle } = this.$store.state.order
            return ({
                typeProject: orderDetailsTitle.typeProject,
                titleProject: orderDetailsTitle.titleProject,
                steps: [
                    ...this.breadcrumbs
                ],
                excerpt: this.$route.meta.breadcrumbs.excerpt,
                additionalClass: this.$route.meta.breadcrumbs.additionalClass
            })
        }
    },
    watch: {
        $route() {
            this.breadcrumbs = [
                {
                    title: 'Home',
                    url: '/'
                }
            ]
            this.breadcrumbsBuilder()
        }
    },
    created() {
        this.breadcrumbsBuilder()
    },
    methods: {
        breadcrumbsBuilder() {
            const { breadcrumbs } = this.$route.meta;

            if (this.$route.name !== 'account') {
                if (breadcrumbs.parent_route_names) {
                    this.breadcrumbs = [...this.breadcrumbs, ...this.findParrents(breadcrumbs.parent_route_names)]
                }
                if (this.$route.name === 'order_details') {
                    this.breadcrumbs.push({
                        title: 'Details',
                        url: this.$route.path
                    })
                } else {
                    this.breadcrumbs.push({
                        title: this.$route.params.id || breadcrumbs.name,
                        url: this.$route.path
                    })
                }
            }
        },
        findParrents(parent_route_names) {
            const result = []
            parent_route_names.forEach((route_name) => {
                const find = routes.find((item) => item.name === route_name)
                result.push({
                    title: find.meta.breadcrumbs.name,
                    url: find.path
                })
            })
            return result
        }
    }
}
</script>

<style lang="scss">
    .breadcrumbs_layout{
        min-height: 100dvh;
        display: flex;
        justify-content: center;
        padding-left: 250px;
        @include media768max {
            padding-left: 80px;
        }
        @include media480max {
            flex-direction: column;
            justify-content: flex-start !important;
            padding-left: 0 !important;
            .header__logo {
                justify-content: flex-start !important;
                width: 50%;
            }
        }
        #page-footer{
            margin-top: auto;
        }
        .header-menu {
            display: none;
            @include media480max {
                display: flex;
            }
        }
    }
</style>
