<template>
    <div>
        <!-- snack bar notification -->
        <snack-bar />
        <!-- confirm action, yes or no -->
        <confirm-modal ref="modal_confirm" />
        <!-- report a problem modal -->
        <report-modal ref="modal_report_problem" />
        <!-- page overlay -->
        <overlay />
        <!-- auth popup -->
        <confirm-auth-popup />

        <report-modal ref="report-modal" />
    </div>
</template>
<script>
import SnackBar from '@/components/SnackBar.vue'
import Overlay from '@/components/modals/Overlay'
import ConfirmAuthPopup from '@/components/popups/ConfirmAuthPopup'
import ConfirmModal from '@/components/modals/ConfirmModal'
import ReportModal from '@/components/modals/ReportModal'

export default {
    name: 'GlobalModals',
    components: {
        SnackBar,
        Overlay,
        ConfirmModal,
        ReportModal,
        ConfirmAuthPopup
    },
    async mounted() {
        // initialize modals
        this.$root.$confirm = this.$refs.modal_confirm.open
        this.$root.$report = this.$refs['report-modal'].open
        this.$root.$confirm_password = this.$refs.modal_report_problem.open
    }
}

</script>
