/* eslint-disable import/no-mutable-exports */
/* eslint-disable import/no-extraneous-dependencies */
import { Workbox } from 'workbox-window';

let wb;

if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
    wb = new Workbox(`${process.env.BASE_URL}service-worker.js`);

    wb.register();
} else {
    wb = null;
}

export default wb;
