<template>
    <button
        class="btn-base"
        :class="[disabledBtn, animatedBtn, defaultBtn, smallBtn, largeBtn, hoverBtn, classes, animateBtn]"
        :style="{width, height}"
        :type="type"
        @click="buttonClick"
    >
        <template v-if="loading">
            <button-loader
                :message="loadingMessage"
            />
            <span
                v-if="loadingMessage"
                :class="{'is_loading': loading}"
            >
                {{ loadingMessage }}
            </span>
        </template>
        <template v-else>
            <slot />
        </template>
    </button>
</template>

<script>
import ButtonLoader from '@/components/common/ButtonLoader.vue';

export default {
    name: 'CustomButton',
    components: {
        ButtonLoader
    },
    props: {
        loading: {
            type: Boolean
        },
        loadingMessage: {
            type: String,
            required: false
        },
        height: {
            type: String
        },
        width: {
            type: String
        },
        type: {
            type: String
        },
        classes: {
            type: String
        },
        disabled: Boolean,
        animated: Boolean,
        default: Boolean,
        bordered: Boolean,
        small: Boolean,
        large: Boolean,
        hover: Boolean,
        loaderAnimate: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        disabledBtn() {
            return this.disabled ? 'btn-disabled' : ''
        },
        animatedBtn() {
            return this.animated ? 'btn-main cart-btn-empty cart-btn-animated' : ''
        },
        defaultBtn() {
            return this.default ? 'btn-main' : ''
        },
        smallBtn() {
            return this.small ? 'small-btn' : ''
        },
        largeBtn() {
            return this.large ? '' : ''
        },
        hoverBtn() {
            return this.hover ? 'hovered' : ''
        },
        animateBtn() {
            return this.loaderAnimate && this.loading ? 'btn_base--movelinght' : ''
        }
    },
    methods: {
        buttonClick() {
            this.$emit('on-btn-click')
        }
    }
}
</script>

<style lang="scss" scoped>
    .btn-base {
        display: flex;
        align-items: center;
        justify-content: center;
        .is_loading{
            margin-left: 15px;
        }
    }
    .btn-base.hovered {
        transition: .4s;
        &:hover {
            background: $btn-base-hovered--bg !important;//$main-color;
            svg {
                color: $btn-base-hovered-svg--bg;//#fff;
            }
        }
    }
    .small-btn {
        margin: 5px 5px 5px auto;
        background: transparent!important;
        border: 1px solid #e9e9e9;
        padding: 0;
        svg {
            width: 15px;
            color: $main-color;
        }
    }
    .btn_base--movelinght{
        position: relative;
        overflow: hidden;
        &:before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 1.5em;
            height: 100%;
            background-color: rgba(#fff, 0.6);
            transform: translateX(-4em) skewX(-45deg);
            animation: move-light 1s infinite;
        }
    }

    @keyframes move-light {
        from {
            transform: translateX(-4em) skewX(-45deg)
        }
        to {
            transform: translateX(350px) skewX(-45deg)
        }
    }
</style>
