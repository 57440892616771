export class SchemaSupport {
    constructor({
        data_support = {
            subject: '',
            chk_callback: false,
            chk_linked: false,
            linked_to_order: '',
            type: '',
            problem: '',
            resolution: '',
            message: ''
        },
        data_client = {
            firstname: '',
            lastname: '',
            email: ''
        },
        clientid = ''
    } = {}) {
        this.data_client = data_client
        this.data_support = data_support
        this.clientid = clientid
    }
}

export function createSupport(data) {
    return Object.freeze(new SchemaSupport(data));
}
