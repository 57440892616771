export class SchemaPhone {
    constructor({
        phone_number = '',
        country = {
            phone_code: '',
            iso: ''
        }
    } = {}) {
        this.phone_number = phone_number
        this.country = country
    }
}
