import {
    required,
    decimal
} from 'vuelidate/lib/validators'

export const validationRules = {
    amount: {
        required,
        decimal
    }
};
