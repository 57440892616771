<template>
    <fieldset class="form-input__fieldset">
        <legend
            v-if="!isLoginForm"
            class="form-input__legend"
            :class="{ 'error-font-color': validationError }"
            :style="{
                'background-color': bg_color
            }"
        >
            {{ legend }}
        </legend>
        <component
            :is="prependIcon"
            class="form-input__prepend-icon"
        />
        <input
            ref="input"
            class="form-input__input"
            :style="{'background-color': bg_color}"
            :class="{ 'error-border': validationError, 'success': !validationError && value, 'form-input__input--placeholder-animation': isLoginForm}"
            :type="type"
            :autofocus="autofocus"
            :name="name"
            :placeholder="placeholder"
            :value="field"
            :disabled="disabled"
            :maxlength="maxlength"
            :autocomplete="autocomplete"
            @input="onChange($event)"
            @blur="blur($event)"
            @focus="focus($event)"
        >
        <label
            v-if="isLoginForm"
            class="form-input__label"
            :class="{ 'error-font-color': validationError, 'label-margin-left': !prependIcon }"
            :style="{
                'background-color': bg_color
            }"
        >
            {{ legend }}
        </label>
        <span @click="emitEvent">
            <component
                :is="appendIcon"
                class="form-input__append-icon"
            />
        </span>
        <span v-if="name === 'email'">
            <component
                :is="emailStatusIcon"
                class="form-input__append-icon"
            />
        </span>
        <!-- clearable -->
        <span v-if="clearable">
            <CloseIcon
                class="form-input__append-icon"
                @click="clearInput"
            />
        </span>
        <!-- clearable -->

        <div
            v-if="validationError"
            class="form-input__error"
            :style="{
                'background-color': bg_color
            }"
        >
            {{ validationError }}
        </div>
    </fieldset>
</template>

<script>
import EmailOutline from 'mdi-vue/EmailOutline.vue'
import Lock from 'mdi-vue/Lock.vue'
import Account from 'mdi-vue/Account.vue'
import AccountOutline from 'mdi-vue/AccountOutline.vue'
import Eye from 'mdi-vue/Eye.vue'
import EyeOff from 'mdi-vue/EyeOff.vue'
import Link from 'mdi-vue/Link.vue'

import Close from 'mdi-vue/Close.vue'

export default {
    name: 'FormInput',
    components: {
        EmailOutlineIcon: EmailOutline,
        LockIcon: Lock,
        AccountIcon: Account,
        EyeIcon: Eye,
        EyeOffIcon: EyeOff,
        CloseIcon: Close,
        AccountOutline,
        LinkIcon: Link
    },
    props: {
        value: {
            type: String
        },
        autofocus: {
            type: Boolean
        },
        autocomplete: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        prependIcon: {
            type: String
        },
        appendIcon: {
            type: String
        },
        legend: {
            type: String
        },
        type: {
            type: String
        },
        name: {
            type: String
        },
        field: {
            type: String
        },
        placeholder: {
            type: String,
            default: ' '
        },
        errors: {
            type: String
        },
        is_valid: {
            type: Boolean
        },
        bg_color: {
            type: String
        },
        clearable: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean
        },
        maxlength: {
            type: Number,
            required: false
        }
    },
    data() {
        return {
            val: ''
        };
    },
    computed: {
        validationError() {
            return this.errors ? this.errors : '';
        },
        emailStatusIcon() {
            // eslint-disable-next-line no-nested-ternary
            return this.loading && this.value ? 'LoadingIcon' : !this.validationError && this.value ? 'SuccessIcon' : this.validationError ? 'ErrorIcon' : ''
        },
        isLoginForm() {
            if (this.$route.name === 'register'
                || this.$route.name === 'login'
                || this.$route.name === 'forgot-password'
                || this.$route.name === 'account'
                || this.$route.name === 'orders'
                || this.$route.name === 'friends') {
                return true
            }
            return false
        }
    },
    methods: {
        onChange(event) {
            this.$emit('update:field', event.target.value);
            this.$emit('onChange', event.target.value)
        },
        emitEvent() {
            this.$emit('clicked');
        },
        blur(event) {
            this.$emit('blur', event.target.value)
        },
        focus(event) {
            this.$emit('focus', event.target.value)
        },
        clearInput() {
            this.value = ''
        }
    }
};
</script>

<style lang="scss" >
.form-input {
    &__prepend-icon {
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translateY(-50%);
        color: rgba(0, 0, 0, 0.54);
        & + .form-input__input {
            padding-left: 40px;
        }
    }
    &__append-icon {
        position: absolute;
        cursor: pointer;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
        color: rgba(0, 0, 0, 0.54);
    }

    &__fieldset {
        border: $input-border-success;
        border-radius: $input-border-radius;
        max-height: 50px;
        height: 50px;
        padding: 0;
        position: relative;
        width: 100%;
        &.error-border {

            .form-input__error {
                padding: 0 3px;
            }
        }
    }

    &__legend {
        display: table;
        color: #b3bbc6;
        caret-color: #b3bbc6;
        padding: 2px 5px 2px 5px;
        font-size: 80%;
        position: absolute;
        left: 6px;
        top: -8px;
        background-color: white;
        border: none;
        z-index: 1;
        line-height: 1;
    }

    &__input {
        box-sizing: border-box;
        display: inline-block;
        padding: 10px 40px 10px 10px;
        width: 100%;
        user-select: text;
        line-height: 46px;
        outline: none;
        border-radius: $input-border-radius;
        font-family: $font-family-base, sans-serif;
        max-height: 48px;
    }
    &__error {
        display: inline;
        width: auto;
        text-align: left;
        color: $error-color;
        caret-color: $error-color;
        font-size: 12px;
        min-height: 14px;
        position: relative;
        position: absolute;
        left: 13px;
        bottom: -7px;
        background: $white;
    }
}
</style>
