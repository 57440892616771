<template>
    <div
        v-if="regionObject"
        v-click-outside="handleBlur"
        class="form-select form-select-country"
        :class="{ 'error-border': validationError, 'success': isValid, 'empty' : !regionObject, 'disabled': REGIONS.length < 1}"
        @click="openList"
    >
        <fieldset class="form-select__fieldset form-select-country__fieldset">
            <legend class="form-select__legend">
                {{ label }}
            </legend>
            <div
                class="form-select-country__block"
            >
                <span
                    v-if="regionObject"
                    id="phone-code"
                    class="phone-code"
                    @click="openListItem = !openListItem"
                >
                    <span>{{ regionObject.text }}</span>
                </span>
                <input
                    id="phone-select__input"
                    ref="code"
                    :style="{paddingLeft: regionObject ? '5px' : '10px'}"
                    class="form-select__input"
                    :disabled="REGIONS.length < 1"
                    type="text"
                    autocomplete="off"
                    @focus="$event.target.select()"
                    @keyup="onInputChanged($event)"
                >
                <span
                    :class="{active: openListItem}"
                    class="select-arrow"
                >
                    <chevron-down @click="openListItem = !openListItem" />
                </span>
            </div>
            <!-- dropdown select -->
            <ul
                v-if="openListItem"
                class="form-select__list"
            >
                <li
                    v-for="(item, index) in regionList"
                    :key="index"
                    class="form-select__list-item"
                    @click="selectItem(item)"
                >
                    {{ item.text }}
                </li>
            </ul>
            <!-- dropdown select -->
            <span class="form-input__error">{{ validationError }}</span>
        </fieldset>
    </div>
</template>
<script>
import { sortBy } from 'lodash'
import ClickOutside from 'vue-click-outside'
import ChevronDown from 'mdi-vue/ChevronDown'
import Api from '@/helpers/api/index.js'

export default {
    name: 'FormSelectRegion',
    directives: {
        ClickOutside
    },
    components: {
        ChevronDown
    },
    props: {
        value: {
            type: String
        },
        label: {
            type: String
        },
        errors: {
            type: String
        },
        isValid: {
            type: Boolean
        },
        selectType: {
            type: String
        },
        country: {
            type: String
        },
        region: {
            type: String
        }
    },
    data() {
        return {
            openListItem: false,
            REGIONS: [],
            regionList: [],
            search: ''
        }
    },
    computed: {
        validationError() {
            return this.errors ? this.errors : ''
        },
        regionObject() {
            return this.REGIONS.find((item) => item.text === this.region) || { text: '' }
        }
    },
    watch: {
        country() {
            this.getRegions()
        }
    },
    mounted() {
        if (this.country) {
            this.getRegions()
        }
    },
    methods: {
        getRegions() {
            new Promise((resolve) => {
                Api.get('/api/data/regions', {
                    country: this.country
                })
                    .then((response) => {
                        this.REGIONS = sortBy(response.data, ['text'])
                        this.$emit('checkRequired', this.REGIONS.length)
                        resolve('success')
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            })
        },
        openList() {
            this.openListItem = !this.openListItem;
            if (!this.regionObject) {
                this.regionList = [...this.REGIONS]
                return
            }
            this.regionList = [...this.REGIONS.filter((item) => item.value !== this.regionObject.value)]
        },
        async selectItem(value) {
            await this.$emit('update:field', value.text)
            this.openListItem = false
            this.search = ''
            this.$refs.code.value = ''
        },
        handleBlur() {
            if (this.openListItem) {
                this.openListItem = false
            }
        },
        onInputChanged(event) {
            this.openListItem = true
            if (event.code === 'Backspace' && this.search.length === 0) {
                this.$emit('update:field', '')
                this.$emit('checkRequired', this.REGIONS.length)
                return;
            }
            this.search = event.target.value
            if (!this.search) {
                this.regionList = this.REGIONS
                return
            }
            const regionsArray = this.REGIONS.filter((item) => item.text.toLowerCase().includes(this.search.toLowerCase()))
            this.regionList = regionsArray.filter((item) => item.text !== this.regionObject.text)
        }
    }
}
</script>

<style scoped lang="scss">
    .form-select {
        outline: none;
        border-radius: $input-border-radius 0 0 $input-border-radius;
        max-height: 50px;
        width: 100%;
        &.success.empty {
            border: 1px solid #D3D9E2 !important;
        }
        &.error-border {
            border: 1px solid red !important;
        }
        &.success {
            border: 1px solid #27d91f!important;
        }
        &.disabled {
            border: 1px solid rgba(0, 0, 0, 0.12) !important;
            background-color: rgba(0,0,0,.12);
            color: #1e2838 !important;
            pointer-events: none;
        }
        &-country__block {
            display: flex;
            align-items: center;
            height: 48px;
            max-height: 48px;
            border-radius: $input-border-radius 0 0 $input-border-radius;
            cursor: pointer;
            box-sizing: border-box;
            width: 100%;
            .active {
                svg {
                    transform: rotate(-180deg);
                }
            }
            .form-select__input {
                height: unset;
            }
        }
        &__icon {
            position: absolute;
            top: 10px;
            left: 12px;
        }
        &__fieldset {
            position: relative;
            border: none;
            outline: none;
        }
        &__legend {
            display: table;
            color: #b3bbc6;
            caret-color: #b3bbc6;
            padding: 2px 5px 2px 5px;
            font-size: 80%;
            position: absolute;
            left: 6px;
            top: -10px;
            background-color: white;
            border: none;
        }
        &__input {
            display: inline-block;
            border: none !important;
            outline: none;
            font-family: $font-family-base, sans-serif;
            &:focus, &:active {
                border: none !important;
            }
            &.empty {
                padding-left: 10px;
            }
        }
        &__error {
            display: inline;
            width: auto;
            text-align: left;
            color: $error-color;
            caret-color: $error-color;
            font-size: 12px;
            min-height: 14px;
            position: relative;
            position: absolute;
            left: 13px;
            bottom: -7px;
            background: white;
        }
        &__list {
            overflow-y: scroll;
            max-height: 270px;
            background-color: white;
            position: absolute;
            z-index: 10;
            top: 55px;
            text-align: left;
            width: 100%;
            margin: 0;
            padding: 0;
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
                0 3px 14px 2px rgba(0, 0, 0, 0.12);
            border-radius: 4px;
        }
        &__list-item {
            display: flex;
            flex-direction: row;
            padding: 15px;
            background-color: #fff;
            margin-bottom: 1px;
            cursor: pointer;
            &:hover {
                background-color: lightgrey;
            }
            .flag-icon {
                margin-right: 10px;
            }
        }
        .phone-code {
            cursor: pointer;
            margin-left: 10px;
            font-size: 14px;
            display: flex;
            align-content: center;
            white-space: nowrap;
            align-items: center;
            .flag-icon {
                margin-right: 4px;
            }
        }
    }
    .select-arrow {
        margin-right: 10px;
    }
    .form-select-country {
        .form-select__fieldset::after, .form-select-writers__fieldset::after {
            display: none;
        }
    }
</style>
