<template>
    <div
        v-if="show"
        ref="popover"
        class="tooltip-lg"
        :class="{show: show, animate: animate}"
        :data-placement="placements[0]"
        :style="{ position: position, left: leftPos + 'px', top: topPos + 'px' }"
    >
        <span
            v-if="closable"
            class="tooltip-lg--close"
            @click="onClose"
        >
            <close />
        </span>
        <slot />
    </div>
</template>

<script>
import Close from 'mdi-vue/Close'

export default {
    components: {
        Close
    },
    props: {
        closable: {
            type: Boolean
        },
        target: {
            type: [Element, Object],
            required: true,
            default: null
        },
        placement: {
            type: String,
            required: true
        },
        animate: {
            type: Boolean
        },
        position: {
            type: String,
            required: false,
            default: 'absolute'
        },
        updatePosition: {
            type: Boolean
        }
    },
    data: () => ({
        leftPos: 0,
        topPos: 0,
        show: true,
        closed: false
    }),
    computed: {
        placements() {
            return this.placement.split(' ')
        }
    },
    watch: {
        updatePosition() {
            this.reposition()
        }
    },
    mounted() {
        const i = setInterval(() => {
            this.reposition()
        }, 50)
        setTimeout(() => {
            clearInterval(i)
        }, 500)
        document.body.appendChild(this.$el);
        window.addEventListener('resize', this.reposition);
    },
    destroyed() {
        try {
            document.body.removeChild(this.$el); // this is inside a try because if the tooltip is displayed with a v-if then we don't need to remove it manually
        } catch (e) {
            console.error(e)
        }
        window.removeEventListener('resize', this.reposition);
    },
    updated() {
        setTimeout(() => {
            this.reposition()
        }, 30)
    },
    methods: {
        reposition() {
            if (this.target.$el.clientWidth <= 0) {
                this.show = false
                return
            }
            if (this.target && !this.closed) {
                // check if it's a vue object or an actual HTML el
                const targetEl = this.target.$el ?? this.target
                // eslint-disable-next-line no-prototype-builtins
                const targetBoundingRect = targetEl.getBoundingClientRect();
                const popoverBoundingRect = this.$refs.popover?.getBoundingClientRect();
                const placementMain = this.placements[0];
                const placementSecondary = this.placements[1];

                const offset = 20;
                let left;
                let top;
                if (window.getComputedStyle(targetEl)?.getPropertyValue('display') === 'none') {
                    this.show = false
                    return
                }
                this.show = true
                switch (placementMain) {
                case 'top':
                    if (placementSecondary) {
                        if (placementSecondary === 'start') {
                            left = targetBoundingRect.left;
                        } else if (placementSecondary === 'end') {
                            left = targetBoundingRect.left + targetBoundingRect.width - popoverBoundingRect.width;
                        }
                    } else {
                        left = targetBoundingRect.left + (targetBoundingRect.width / 2) - (popoverBoundingRect.width / 2);
                    }
                    top = targetBoundingRect.top + window.scrollY - popoverBoundingRect.height - offset;
                    break;
                case 'right':
                    left = targetBoundingRect.left + targetBoundingRect.width + offset;
                    if (placementSecondary) {
                        if (placementSecondary === 'start') {
                            top = targetBoundingRect.top + window.scrollY;
                        } else if (placementSecondary === 'end') {
                            top = targetBoundingRect.top + window.scrollY + targetBoundingRect.height - popoverBoundingRect.height;
                        }
                    } else {
                        top = targetBoundingRect.top + window.scrollY + (targetBoundingRect.height / 2) - (popoverBoundingRect.height / 2);
                    }
                    break;
                case 'bottom':
                    if (placementSecondary) {
                        if (placementSecondary === 'start') {
                            left = targetBoundingRect.left;
                        } else if (placementSecondary === 'end') {
                            left = targetBoundingRect.left + targetBoundingRect.width - popoverBoundingRect.width;
                        }
                    } else {
                        left = targetBoundingRect.left + (targetBoundingRect.width / 2) - (popoverBoundingRect.width / 2);
                    }
                    top = targetBoundingRect.top + window.scrollY + targetBoundingRect.height + offset;
                    break;
                case 'left':
                    left = targetBoundingRect.left - popoverBoundingRect.width - offset;
                    if (placementSecondary) {
                        if (placementSecondary === 'start') {
                            top = targetBoundingRect.top + window.scrollY;
                        } else if (placementSecondary === 'end') {
                            top = targetBoundingRect.top + window.scrollY + targetBoundingRect.height - popoverBoundingRect.height;
                        }
                    } else {
                        top = targetBoundingRect.top + window.scrollY + (targetBoundingRect.height / 2) - (popoverBoundingRect.height / 2);
                    }
                    break;
                case 'center':
                    left = targetBoundingRect.left + (targetBoundingRect.width / 2) - (popoverBoundingRect.width / 2);
                    top = targetBoundingRect.top + window.scrollY + (targetBoundingRect.height / 2) - (popoverBoundingRect.height / 2);
                    break;
                default:
                    break;
                }

                this.leftPos = Math.round(left);
                this.topPos = Math.round(top);
            }
        },
        onClose() {
            this.show = false
            this.closed = true
        }
    }
}
</script>

<style lang="scss">
    .tooltip-lg {
        box-sizing:border-box;
        box-shadow: 0 0 0 $main-color;
        background:rgba(255,255,255,0.95);
        border:5px solid $main-color;
        border-radius:2px;
        font-family: 'Roboto', sans-serif;
        font-size:15px;
        line-height:1.5;
        opacity:0;
        position:absolute;
        transition:transform 0.4s ease-in-out, opacity 0.4s ease-in-out, visibility 0s linear 0.4s;
        padding:10px; transform:
        rotate3d(1,1,0,15deg);
        transform-origin: 0 100%;
        visibility:hidden;
        width:220px;
        z-index:99999;

        &:before {
            background: #fff;
            content: '';
            border-color:$main-color;
            border-style: solid;
            border-width: 5px 0 0 5px;
            border-radius: 0 0 20px 3px;
            height: 20px;
            transform: rotate(45deg);
            position: absolute;
            width: 20px;
        }
        &[data-placement='top'] {
            &.animate {
                animation: notice-me-top 2s cubic-bezier(0.24, 0, 0.38, 1) infinite;
            }
            &:before {
                bottom:-16px;
                left:calc(50% - 16px);
                transform: rotate(225deg);
            }
        }
        &[data-placement='right'] {
            &.animate {
                animation: notice-me-right 2s cubic-bezier(0.24, 0, 0.38, 1) infinite;
            }
            &:before {
                top:calc(50% - 10px);
                left:-16px;
                transform: rotate(-45deg);
            }
        }
        &[data-placement='bottom'] {
            &.animate {
                animation: notice-me-bottom 2s cubic-bezier(0.24, 0, 0.38, 1) infinite;
            }
            &:before {
                top:-15px;
                left:calc(50% - 16px);
            }
        }
        &[data-placement='left'] {
            &.animate {
                animation: notice-me-left 2s cubic-bezier(0.24, 0, 0.38, 1) infinite;
            }
            &:before {
                top:calc(50% - 10px);
                right:-16px;
                transform: rotate(-225deg);
            }
        }
        &[data-placement='center'] {
            &:before {
                display:none;
            }
        }
        &--close {
            position: absolute;
            top: 6px;
            right: 6px;
            cursor: pointer;
        }
        &.show {
            visibility: visible;
            opacity:1;
            transform: none;
            transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out, visibility 0s linear;
        }
        &.secondary {
            border-color:rgba(172,119,250,0.5);
            box-shadow: 0 0 0 rgb(172,119,250);
            z-index: 3;
            &:before {
                border-color:rgba(172,119,250,0.5);
            }
            .mu-raised-button {
                color: rgb(172,119,250);
            }
        }

        p {
            margin-top:0;
            &:last-child {
                margin-bottom:0;
            }
        }

        .mu-raised-button {
            color: $main-color;
        }

        .close-btn {
            opacity:0.4;
            float:right;
            margin:-10px -16px 0 0;
        }
    }

    @keyframes notice-me-left {
        0% { transform:translateX(0); }
        10% { transform:translateX(-8px); }
        20% { transform:translateX(0); }
        30% { transform:translateX(-8px); }
        40% { transform:translateX(0); }
        50% { transform:translateX(0); }
    }

    @keyframes notice-me-right {
        0% { transform:translateX(0); }
        10% { transform:translateX(8px); }
        20% { transform:translateX(0); }
        30% { transform:translateX(8px); }
        40% { transform:translateX(0); }
        50% { transform:translateX(0); }
    }

    @keyframes notice-me-bottom {
        0% { transform:translateY(0); }
        10% { transform:translateY(8px); }
        20% { transform:translateY(0); }
        30% { transform:translateY(8px); }
        40% { transform:translateY(0); }
        50% { transform:translateY(0); }
    }

    @keyframes notice-me-top {
        0% { transform:translateY(0); }
        10% { transform:translateY(-8px); }
        20% { transform:translateY(0); }
        30% { transform:translateY(-8px); }
        40% { transform:translateY(0); }
        50% { transform:translateY(0); }
    }
</style>
