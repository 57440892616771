<template>
    <div class="form-input-type-number">
        <div
            class="form-input-type-number__fieldset"
            :class="{ 'error-border': validationError }"
        >
            <legend
                class="form-select__legend"
                :class="{ 'error': validationError }"
            >
                {{ label }}
            </legend>

            <button
                class="form-input-type-number__button form-input-type-number__button--left"
                :disabled="value === min_value"
                @click="onClick('reduce')"
            >
                <MinusIcon class="form-input-type-number__icon" />
            </button>
            <span
                v-if="show_value"
                class="form-input-type-number__value"
            >
                <input
                    :type="with_text ? 'text' : 'number'"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    :value="with_text ? `${text}${value}` : value"
                    @input="onChange"
                >
            </span>
            <button
                class="form-input-type-number__button form-input-type-number__button--right"
                @click="onClick('increase')"
            >
                <PlusIcon class="form-input-type-number__icon" />
            </button>
            <div
                v-if="validationError"
                class="form-input-type-number__error"
            >
                {{ validationError }}
            </div>
        </div>
    </div>
</template>

<script>
import Minus from 'mdi-vue/Minus.vue';
import Plus from 'mdi-vue/Plus.vue';

export default {
    name: 'FormInputTypeNumber',
    components: {
        MinusIcon: Minus,
        PlusIcon: Plus
    },
    props: {
        // eslint-disable-next-line vue/require-prop-types
        value: {},
        label: {
            type: String
        },
        errors: {
            type: String
        },
        name: {
            type: String
        },
        show_value: {
            type: Boolean
        },
        min_value: {
            type: Number
        },
        init_value: {
            type: Number
        },
        with_text: {
            type: Boolean,
            default: false
        },
        text: {
            type: String
        }
    },
    computed: {
        validationError() {
            return this.errors ? this.errors : ''
        }
    },
    created() {
        if (this.init_value === 1 && !this.value) {
            this.$emit('increase');
        }
    },
    methods: {
        onClick(eventName) {
            this.$emit(eventName)
        },
        onChange(event) {
            // eslint-disable-next-line no-useless-escape
            event.target.value = event.target.value.replace(/[^.\d]+/g, '').replace(/^([^\.]*\.)|\./g, '$1')
            this.$emit('update:field', +event.target.value)
        }
    }
}
</script>

<style lang="scss">
.form-input-type-number {
    position: relative;

    &__fieldset {
        border: $input-border-success;
        border-radius: $input-border-radius ;
        position: relative;
        text-align: center;
        min-height: 48px;
        display: flex;
        align-items: center;

        span.form-input-type-number__value input {
            padding-left: 0;
            border-radius: $input-border-radius;
        }
    }

    &__icon {
        color: $font-color-main;
    }

    &__button {
        position: relative;
        min-height: 38px;
        background-color: white;
        border: none;
        outline: none;
        &:hover {
            color: $second-color;
            cursor: pointer;
        }

        &--left {
            position: absolute;
            left: 4px;
            top: 50%;
            transform: translateY(-50%);
            background: transparent;
            &::after {
                content: '';
                position: absolute;
                display: block;
                height: 24px;
                width: 1px;
                background-color: #d3d9e2;
                right: -1px;
                top: 8px;
            }
        }

        &--right {
            position: absolute;
            right: 4px;
            top: 50%;
            transform: translateY(-50%);
            background: transparent;
            &::before {
                content: '';
                position: absolute;
                display: block;
                height: 24px;
                width: 1px;
                background-color: #d3d9e2;
                left: -1px;
                top: 8px;
            }
        }
    }

    &__value {
        width: 100%;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        input {
            width: 100%;
            height: 48px;
            font-family: $font-family-base, sans-serif;
            font-size: 14px;
            text-align: center;
            border: none;
            outline: none;
        }
    }
    &__error {
        display: inline;
        width: auto;
        text-align: left;
        color: $error-color;
        caret-color: $error-color;
        font-size: 12px;
        min-height: 14px;
        position: absolute;
        left: 13px;
        bottom: -7px;
        background: white;
        padding: 0 3px;
    }
}
.form-input-type-number:focus {
    outline: none;
}

</style>
