import Api from '@/helpers/api/index.js'
import { creditCardFixDate, bitfinexIsValid } from '@/helpers/utils'

import {
    ACTION_CREATE_BATCH,
    ACTION_PAY_WITH_CARD_FIRST,
    ACTION_PAY_WITH_CARD_SECOND,
    ACTION_GET_CART_FROM_BACKEND,
    ACTION_DELETE_ORDER,
    ACTION_WORLDPAY_GET_TOKEN,
    ACTION_PAY_WORLDPAY_350,
    ACTION_BITFINEX_PAYMENT
} from '@/store/modules/checkout/action-types.js'

import {
    SET_CART_FROM_BACKEND,
    SET_CART_CALCULATION,
    REMOVE_FROM_CART,
    SET_CART_LOADING,
    SET_BITFINEX_RESULT
} from './mutation-types.js'

export const actions = {
    async [ACTION_WORLDPAY_GET_TOKEN]() {
        const { data } = await Api.post('/api/order-batch/pay/worldpay/generate-jwt-token')
        return data.token
    },
    async [ACTION_CREATE_BATCH]({ getters }) {
        const orders = getters.getterCart.map((item) => item.orderid)
        const { data } = await Api.post('/api/order-batch/create', { orders })
        return data
    },
    async [ACTION_GET_CART_FROM_BACKEND]({ commit }) {
        commit(SET_CART_LOADING)
        const { data } = await Api.get('/api/order/checkout/get-cart-object')
        commit(SET_CART_FROM_BACKEND, data.orders)
        commit(SET_CART_CALCULATION, { ...data.payment, orders: data.orders_count })
    },
    async [ACTION_DELETE_ORDER]({ commit, dispatch }, payload) {
        commit(SET_CART_LOADING)
        await Api.delete('/api/order/checkout/standard/delete', payload)
        commit(REMOVE_FROM_CART, payload)
        dispatch(ACTION_GET_CART_FROM_BACKEND)
    },
    async [ACTION_PAY_WORLDPAY_350](ctx, payload) {
        const { data } = await Api.get('/api/order-batch/pay/worldpay-bg350/get-form', { batchid: payload.batchid })
        const div = document.createElement('div')
        div.style.display = 'none'
        div.innerHTML = data
        document.body.appendChild(div)
        div.querySelector('form').submit()
    },
    async [ACTION_PAY_WITH_CARD_FIRST]({ rootState }, payload) {
        const {
            form_data_credit_card
        } = rootState.checkout
        const cardData = creditCardFixDate({ ...form_data_credit_card })
        const { data } = await Api.post('/api/order-batch/pay/worldpay/first-connect', {
            ...payload,
            ...cardData
        })
        if (data.action === 'redirect') {
            const div = document.createElement('div')
            div.style.display = 'none'
            div.innerHTML = data.form
            document.body.appendChild(div)
            div.querySelector('form').submit()
            return 'redirect'
        }
        return data
    },
    async [ACTION_PAY_WITH_CARD_SECOND]({ rootState }, payload) {
        const {
            form_data_credit_card
        } = rootState.checkout
        const cardData = creditCardFixDate({ ...form_data_credit_card })
        const { data: token } = await Api.get('/api/order-batch/pay/worldpay/get-token', { token: payload.query.PaRes })
        await Api.post('/api/order-batch/pay/worldpay/second-connect', {
            invoice_id: payload.batchid,
            ...cardData,
            PaRes: token
        })
    },
    async [ACTION_BITFINEX_PAYMENT]({ commit, state }, payload) {
        if (state.bitfinex) {
            const isBitfinexValid = bitfinexIsValid(state.bitfinex.t, state.bitfinex.duration)
            const isCurrentBatch = state.bitfinex?.orderId === payload.batchid
            if (isBitfinexValid && isCurrentBatch) {
                return
            }
        }
        commit(SET_BITFINEX_RESULT, null)
        const { data } = await Api.post('/api/order-batch/pay/bitfinex-pay/submit-invoice', payload)
        commit(SET_BITFINEX_RESULT, data)
    }
}
