/* eslint-disable class-methods-use-this */

export class VuexHelper {
    constructor(build) {
        if (build === '') throw new Error('No build id provided')
        this.newId = build

        if (this.isSet() === false) {
            this.setNewId()
        }
    }

    hasChanged() {
        return this.newId !== this.getOldId()
    }

    reset() {
        window.localStorage.removeItem('vuex')
    }

    getOldId() {
        if (window.localStorage.getItem('build') === null) return false
        const { id } = JSON.parse(window.localStorage.getItem('build'))
        return id
    }

    isSet() {
        /* eslint-disable no-unneeded-ternary */
        if (window.localStorage.getItem('build') === null) return false
        const id = this.getOldId()
        if (typeof id === 'undefined') return false
        if (id === '') return false
        if (!id) return false
        return true
    }

    setNewId() {
        const data = {
            id: this.newId
        }
        window.localStorage.setItem('build', JSON.stringify(data))
    }
}
