// import { state } from './state.js';
import { getters } from './getters.js'
import { mutations } from './mutations.js'
import { actions } from './actions.js'

export const notification = {
    namespaced: true,
    mutations,
    actions,
    getters
}
