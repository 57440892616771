<template>
    <div
        class="support-messenger__button"
        :class="{'support-messenger__button--is-auth' : getterIsAuth}"
        @click="$emit('click')"
    >
        <div
            v-if="!showMessenger"
            class="support-messenger__outside-image"
        >
            <img
                :src="`/img/operators/${operatorImage}.jpg`"
                alt="support agent"
                class="support-messenger__image"
            >
            <span

                v-if="unreadCounter && getterIsAuth"
                class="support-messenger__outside-counter"
            >
                {{ unreadCounter }}
            </span>
            <div
                class="support-messenger__outside-close"
                @click.stop="$emit('hide')"
            >
                <img
                    :src="require('@/assets/img/svg/cross.svg')"
                    alt="close"
                >
            </div>
        </div>
        <ChevronDown v-else />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ChevronDown from 'mdi-vue/ChevronDown.vue'

export default {
    components: {
        ChevronDown
    },
    props: {
        showMessenger: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterIsAuth',
            'getterUnreadCounters'
        ]),
        operatorImage() {
            return Math.floor(Math.random() * 7) + 1
        },
        unreadCounter() {
            return this.getterUnreadCounters ? this.getterUnreadCounters.chats_count + this.getterUnreadCounters.open_tickets_count : 0
        }
    }
}
</script>
