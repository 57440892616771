import { getField } from 'vuex-map-fields'

export const getters = {
    getField,
    getterPayment(state) {
        return {
            cartInvalid: state.orders.some((item) => !item.quote),
            ...state.payment
        }
    },
    getCreditCard(state) {
        return state.form_data_credit_card
    },
    getterLoadingStatus(state) {
        return state.loading.status
    },
    getterLoadingMessage(state) {
        return state.loading.message
    },
    getterCart(state) {
        return state.orders
    },
    getterPaymentError(state) {
        return state.payment_error
    },
    getterCardBin(state) {
        return state.form_data_credit_card.cc_number
    },
    getterBitfinexInvices(state) {
        return state.bitfinex?.invoices
    },
    getterBitfinexId(state) {
        return state.bitfinex?.id
    }
}
