<template>
    <div class="support-messenger_empty">
        <p class="support-messenger_empty__title">
            Start a conversation
        </p>
        <div
            class="support-messenger_empty__support"
        >
            <div class="support-messenger_empty__avatars">
                <img
                    src="@/assets/img/support1.jpg"
                    alt="manager"
                >
                <img
                    src="@/assets/img/support3.jpg"
                    alt="manager"
                >
                <img
                    src="@/assets/img/support2.jpg"
                    alt="manager"
                >
            </div>
            <div class="">
                <p class="support-messenger_empty_text">
                    Our usual reply time
                </p>
                <p class="support-messenger_empty_text">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="#fff"
                    ><path
                        fill-rule="evenodd"
                        d="M12.5 7c0 3.0376-2.4624 5.5-5.5 5.5-3.03757 0-5.5-2.4624-5.5-5.5 0-3.03757 2.46243-5.5 5.5-5.5 3.0376 0 5.5 2.46243 5.5 5.5zM14 7c0 3.866-3.134 7-7 7-3.86599 0-7-3.134-7-7 0-3.86599 3.13401-7 7-7 3.866 0 7 3.13401 7 7zM6.27844 3.48219v3.78093l.00105.00104c.00305.07805.03658.1518.09341.20546l.07452.0566L9.6706 9.69708l.15009.03773h.05667c.09341-.01886.14904-.07442.20574-.13102l.4125-.65514c.0355-.04926.0552-.10809.0566-.16876 0-.09329-.0566-.14885-.1501-.20545L7.62817 6.68345V3.48219c0-.14989-.1312-.29979-.30017-.29979h-.74938c-.16898 0-.30018.1499-.30018.29979z"
                        clip-rule="evenodd"
                    /></svg>
                    <b>A few minutes</b>
                </p>
            </div>
        </div>
        <button
            class="support-messenger_empty__btn"
            @click="goToCreate"
        >
            <SendOutline />
            Start a new conversation
        </button>
    </div>
</template>

<script>
import SendOutline from 'mdi-vue/SendOutline.vue'

export default {
    components: {
        SendOutline
    },
    methods: {
        goToCreate() {
            this.$emit('goToCreate', true)
        }
    }
}
</script>
