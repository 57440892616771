<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0)">
            <path
                d="M11.6623 3.00002L11.6619 17"
                stroke="#FF0000"
                stroke-width="1.8"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M11.6612 20.5L11.661 21.5"
                stroke="#FF0000"
                stroke-width="1.8"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect
                    width="24"
                    height="24"
                    fill="white"
                />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'ErrorIcon'
}
</script>
