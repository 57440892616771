<template>
    <div class="progress-bg">
        <div
            v-show="field.length > 0"
            class="progress-list"
        >
            <span
                :class="{'red darken-2' : strength >= 0 && field.length < 7 || field}"
                class="progress"
            />
            <span
                :class="{'orange darken-2' : strength >= 50}"
                class="progress"
            />
            <span
                :class="{'green darken-2' : strength >= 75}"
                class="progress"
            />
        </div>
    </div>
</template>
<script>
export default {
    name: 'ProgressLinear',
    props: {
        validate: {
            type: Object
        }
    },
    data() {
        return {
            strength: 0,
            field: ''
        }
    },
    watch: {
        validate(val) {
            if (val) {
                this.strength = val.strength
                this.field = val.field || ''
            } else {
                this.strength = 0
            }
        }
    }
}
</script>
<style lang="scss">
.progress {
    height: 3px;
    flex-basis: 0%;
    // position: absolute;
    // top: 0;
    transition: .3s;

    &-list {
        display: flex;
        width: 100%;
        height: 3px;
    }

    &-bg {
        position: relative;
        top: 10px;
        width: 100%;
        height: 3px;
        transition: .3s;

        &.red.darken-2 {
            background:rgba(#d32f2f, 0.3);
        }
        &.orange.darken-2{
            background: rgba(#f57c00, 0.3);
        }
        &.green.darken-2 {
            background: rgba(#388e3c, 0.3);
        }
    }

    &.red.darken-2 {
        margin-right: 5px;
        background:#d32f2f;
        flex-basis: 32%;
    }
    &.orange.darken-2{
        margin-right: 5px;
        background:#f57c00;
        flex-basis: 32%;
    }
    &.green.darken-2 {
        background:#388e3c;
        flex-basis: 32%;
    }
}
</style>
