<template>
    <div
        class="form-datepicker"
        :class="{ 'error-border': validationError, 'success-border' : value}"
    >
        <div class="form-datepicker__fieldset">
            <legend
                class="form-datepicker__legend"
                :class="{ 'error': validationError }"
            >
                {{ label }}
            </legend>
            <CalendarRange class="form-datepicker__icon" />
            <datetime
                type="datetime"
                format="DDDD, h:mm a"
                :flow="['date', 'time']"
                use12-hour
                :min-datetime="minDate"
                :minute-step="15"
                monday-first
                :value="field"
                placeholder="Select Date & Time"
                :hide-backdrop="hideBackdrop"
                @input="onDateSelect($event)"
            />
            <div
                v-if="validationError"
                class="form-input__error"
            >
                {{ validationError }}
            </div>
        </div>
    </div>
</template>

<script>
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import CalendarRange from 'mdi-vue/CalendarRange.vue'
import moment from 'moment'

export default {
    name: 'FormDatePicker',
    components: {
        datetime: Datetime,
        CalendarRange
    },
    props: {
        value: {
            type: String
        },
        label: {
            type: String,
            required: true
        },
        errors: {
            type: String
        },
        field: {
            type: String
        },
        hideBackdrop: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        datetime: null
    }),
    computed: {
        minDate() {
            return moment().add(3, 'hours').toISOString();
        },
        validationError() {
            return this.errors ? this.errors : '';
        }
    },
    methods: {
        onDateSelect(event) {
            this.$emit('update:field', event)
        }
    }
};
</script>

<style lang="scss">
.form-datepicker {
    outline: none;
    // border: 1px solid #27d91f ;
    border: $input-border;
    -webkit-border-radius: $input-border-radius;
    -moz-border-radius: $input-border-radius;
    border-radius: $input-border-radius !important;
    max-height: 52px!important;
    width: 100%;
    &.success-border {
        border: $input-border-success;
    }
    &.grey-border {
        border: $input-border;
    }
    &__icon {
        position: absolute;
        top: 12px;
        left: 10px;
        color:rgba(0,0,0,.54);
    }
    &__fieldset {
        -webkit-border-radius: $input-border-radius;
        -moz-border-radius: $input-border-radius;
        border-radius: $input-border-radius;
        position: relative;
        max-height: 50px;
        display: flex;
        justify-content: space-between;

    }

    &__legend {
        display: table;
        color: #b3bbc6;
        caret-color: #b3bbc6;
        padding: 2px 5px 2px 5px;
        font-size: 80%;
        position: absolute;
        left: 6px;
        top: -8px;
        background-color: $white;
    }

    &__error {
        display: inline;
        width: auto;
        text-align: left;
        color: $error-color;
        caret-color: $error-color;
        font-size: 12px;
        min-height: 14px;
        position: relative;
        position: absolute;
        left: 13px;
        bottom: -7px;
        background: $white;
    }

    &.error-border {
        border-color: $error-color;
    }
}

.form-datepicker input {
    font-family: $font-family-base, sans-serif !important;
    border: none;
    padding-left: 40px;
    border-radius: $input-border-radius;
    letter-spacing: normal;
    font-size: 14px;
    width: 100%;
    height: 48px;
}

.form-datepicker .vdatetime-popup__header {
    background-color: $main-color;
}

.form-datepicker .vdatetime-calendar__month__day--selected > span > span,
.vdatetime-calendar__month__day--selected:hover > span > span {
    background-color: $main-color;
}

.form-datepicker .vdatetime-year-picker__item--selected {
    color: $main-color;
}

.form-datepicker .vdatetime-popup__actions__button {
    color: $main-color;

    &--confirm {
        background-color: $main-color;
        color: white;
        border-radius: $main-border-radius;
    }
}

.form-datepicker .vdatetime-year-picker__item--selected,
.vdatetime-month-picker__item--selected,
.vdatetime-time-picker__item--selected {
    color: $main-color;
}

.form-datepicker .vdatetime-popup {
    font-family: $font-family-base, sans-serif !important;
}

.form-datepicker .vdatetime {
    width: 100%;
}

.form-datepicker:focus .form-select__fieldset {
    border-color: $main-color !important;
}

.form-datepicker:focus {
    outline: none;
}
</style>
