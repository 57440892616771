<template>
    <svg
        width="28px"
        height="28px"
        version="1.1"
        viewBox="0 0 700 700"
        xmlns="http://www.w3.org/2000/svg"
        :fill="fill"
    >
        <path d="m86.859 0.027344c-5.3164 0.19922-10.254 2.8008-13.422 7.0781-3.1641 4.2734-4.2148 9.7578-2.8477 14.898l58.309 218.55 257.48 39.41-257.44 39.375-58.344 218.61c-1.1758 4.3711-0.60938 9.0312 1.5781 12.992 2.1914 3.9648 5.8281 6.9297 10.156 8.2656 4.3242 1.3359 9 0.94531 13.043-1.0938l525-262.5c3.8867-1.9531 6.9023-5.2773 8.4688-9.3359 1.5664-4.0547 1.5664-8.5469 0-12.602-1.5664-4.0547-4.582-7.3828-8.4688-9.3359l-525-262.5c-2.6367-1.3125-5.5664-1.9336-8.5078-1.8125z" />
    </svg>
</template>

<script>
export default {
    props: {
        fill: {
            type: String,
            required: false,
            default: 'white'
        }
    }
}
</script>
