const Checkout = () => import(/* webpackChunkName: "checkout" */'@/views/checkout/Checkout.vue')
const BatchPage = () => import(/* webpackChunkName: "checkout" */'@/views/checkout/BatchPage')
const ErrorPayment = () => import(/* webpackChunkName: "successful-payment" */'@/views/checkout/ErrorPayment')
const SuccessfulPayment = () => import(/* webpackChunkName: "successful-payment" */'@/views/checkout/SuccessfulPayment')
const ProccecingPayment = () => import(/* webpackChunkName: "payment-processing" */'@/views/checkout/Proccecing')
const StripeCallback = () => import(/* webpackChunkName: "payment-processing" */'@/views/checkout/stripe/Callback')
const StripeProxyCheckout = () => import(/* webpackChunkName: "payment-processing" */'@/views/checkout/stripe/Proxy')

export default [
    {
    // checkout page
        path: '/checkout',
        component: Checkout,
        name: 'checkout',
        meta: {
            requiresAuth: true,
            layout: 'EmptyLayout',
            breadcrumbs: {
                bgWrapper: true,
                name: 'Checkout',
                additionalClass: 'bc-horizontal',
                parent_route_names: ['order_new']
            }
        }
    },
    // error page
    {
        path: '/checkout/error',
        component: ErrorPayment,
        name: 'checkout_error',
        meta: {
            requiresAuth: true,
            layout: 'EmptyLayout'
        }
    },
    // success page
    {
        path: '/checkout/success',
        component: SuccessfulPayment,
        name: 'checkout_success',
        meta: {
            requiresAuth: true,
            showHeader: true,
            layout: 'EmptyLayout'
        }
    },
    {
        // invoice page
        path: '/checkout/:batchid',
        component: BatchPage,
        name: 'checkout-batch',
        meta: {
            requiresAuth: true,
            layout: 'EmptyLayout',
            breadcrumbs: {
                bgWrapper: true,
                name: 'Payment'
            }
        }
    },
    {
        path: '/processing',
        component: ProccecingPayment,
        name: 'checkout-processing',
        meta: {
            requiresAuth: true,
            layout: 'EmptyLayout'
        }
    },
    {
        path: '/stripe/canceled',
        component: StripeCallback,
        name: 'stripe-canceled',
        meta: {
            // requiresAuth: true,
            layout: 'EmptyLayout',
            breadcrumbs: {
                bgWrapper: false,
                name: '',
                additionalClass: ''
            }
        }
    },
    {
        path: '/stripe/success',
        component: StripeCallback,
        name: 'stripe-success',
        meta: {
            // requiresAuth: true,
            layout: 'EmptyLayout',
            breadcrumbs: {
                bgWrapper: false,
                name: '',
                additionalClass: ''
            }
        }
    },
    {
        path: '/stripe/get-session',
        component: StripeProxyCheckout,
        name: 'stripe-checkout',
        meta: {
            // requiresAuth: true,
            layout: 'EmptyLayout',
            breadcrumbs: {
                bgWrapper: false,
                name: '',
                additionalClass: ''
            }
        }
    }
]
