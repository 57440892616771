<template>
    <div class="notice-messages">
        <transition-group
            name="notice-messages-list"
            tag="div"
        >
            <div
                v-for="notice in reversedList"
                :key="notice.id"
                :class="{
                    'notice-messages__item-success': notice.type === 'success',
                    'notice-messages__item-info': notice.type === 'info',
                    'notice-messages__item-error': notice.type === 'error'
                }"
                class="notice-messages__item"
            >
                <div class="notice-messages__item-inside">
                    <span
                        v-if="notice.type === 'success'"
                        class="notice-messages__item-inside__icon"
                    >
                        <svg
                            id="Слой_1"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 34 34"
                            style="enable-background:new 0 0 34 34;"
                            xml:space="preserve"
                            fill="#fff"
                        >
                            <g>
                                <path
                                    class="st0"
                                    d="M17,0C7.6,0,0,7.6,0,17s7.6,17,17,17s17-7.6,17-17S26.4,0,17,0z M24.4,15.2l-8.2,8.2c-0.7,0.7-1.9,0.7-2.6,0
                                l-4.1-4.1c-0.7-0.7-0.7-1.9,0-2.6c0.7-0.7,1.9-0.7,2.6,0l2.4,2.4c0.2,0.2,0.5,0.2,0.7,0l6.5-6.5c0.7-0.7,1.9-0.7,2.6,0
                                c0.3,0.3,0.5,0.8,0.5,1.3C24.9,14.3,24.7,14.8,24.4,15.2z"
                                />
                            </g>
                        </svg>
                    </span>

                    <span
                        v-if="notice.type === 'info'"
                        class="notice-messages__item-inside__icon"
                    >
                        <svg
                            id="Слой_1"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 34 34"
                            style="enable-background:new 0 0 34 34;"
                            xml:space="preserve"
                            fill="#fff"
                        >
                            <g>
                                <path
                                    class="st0"
                                    d="M17,0.3c-9.4,0-17,7.6-17,17s7.6,17,17,17s17-7.6,17-17S26.4,0.3,17,0.3z M18.2,25.5c-0.3,0.3-0.7,0.4-1.2,0.4
                                s-0.9-0.1-1.2-0.4s-0.4-0.6-0.4-0.9c0-0.4,0.1-0.7,0.4-0.9s0.7-0.4,1.2-0.4s0.9,0.1,1.2,0.4s0.4,0.6,0.4,0.9
                                C18.6,24.9,18.5,25.2,18.2,25.5z M19,11.6l-0.7,7.8c0,0.4-0.2,0.7-0.4,0.9s-0.5,0.3-0.9,0.3s-0.7-0.1-0.9-0.3s-0.4-0.5-0.4-0.9
                                L15,11.6c0-0.4-0.1-0.7-0.1-0.8c0-0.7,0.2-1.2,0.6-1.6s0.9-0.6,1.5-0.6c0.6,0,1.1,0.2,1.5,0.6s0.6,0.9,0.6,1.5
                                C19.1,10.9,19.1,11.2,19,11.6z"
                                />
                            </g>
                        </svg>
                    </span>

                    <span
                        v-if="notice.type === 'error'"
                        class="notice-messages__item-inside__icon"
                    >
                        <svg
                            id="Layer_1"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 512 512"
                            style="enable-background:new 0 0 512 512;"
                            xml:space="preserve"
                            fill="#fff"
                        >
                            <g>
                                <path
                                    d="M507.494,426.066L282.864,53.537c-5.677-9.415-15.87-15.172-26.865-15.172c-10.995,0-21.188,5.756-26.865,15.172
            L4.506,426.066c-5.842,9.689-6.015,21.774-0.451,31.625c5.564,9.852,16.001,15.944,27.315,15.944h449.259
            c11.314,0,21.751-6.093,27.315-15.944C513.508,447.839,513.336,435.755,507.494,426.066z M256.167,167.227
            c12.901,0,23.817,7.278,23.817,20.178c0,39.363-4.631,95.929-4.631,135.292c0,10.255-11.247,14.554-19.186,14.554
            c-10.584,0-19.516-4.3-19.516-14.554c0-39.363-4.63-95.929-4.63-135.292C232.021,174.505,242.605,167.227,256.167,167.227z
                M256.498,411.018c-14.554,0-25.471-11.908-25.471-25.47c0-13.893,10.916-25.47,25.471-25.47c13.562,0,25.14,11.577,25.14,25.47
            C281.638,399.11,270.06,411.018,256.498,411.018z"
                                />
                            </g>
                        </svg>
                    </span>

                    <div
                        class="notice-messages__item-inside__text"
                        v-html="notice.message"
                    />
                    <button
                        class="notice-messages__item-inside__close"
                        color="white"
                        @click="delItem(notice.id)"
                    />
                </div>
            </div>
        </transition-group>
    </div>
</template>

<script>
import { eventBus } from '@/helpers/event-bus';

export default {
    name: 'SnackBar',
    data: () => ({
        list: []
    }),
    computed: {
        reversedList() {
            const list = [...this.list];
            return list.reverse();
        }
    },
    created() {
        eventBus.$on('showSnackBar', (msg, type, isSession) => {
            function randomInteger() {
                let rand = 0 - 0.5 + Math.random() * (999999999999999 + 1);
                rand = Math.round(rand);
                return rand;
            }

            const el = {
                message: msg,
                type,
                id: randomInteger(),
                isSession
            };

            if (!this.list.some((el) => el.isSession)) {
                this.list.push(el);
                setTimeout(this.delItem, 6000, el.id);
            }
        });
    },
    methods: {
        delItem(id) {
            for (let i = 0; i < this.list.length; i += 1) {
                if (this.list[i].id === id) {
                    this.list.splice(i, 1);
                    return;
                }
            }
        }
    }
};
</script>
