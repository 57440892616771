export class FaqData {
    constructor(keyword) {
        this.keyword = keyword || 'paper'
    }

    getData = () => {
        const faq = [
            {
                id: 1,
                q: `Do you resell the ${this.keyword} after delivery?`,
                a: `We guarantee not to resell the ${this.keyword} completed for our clients, that is explicitly stated in our terms of service.`
            },
            {
                id: 2,
                q: `How much will my ${this.keyword} cost?`,
                a: 'The price depends on your deadline, academic level, project type and the number of pages. To get a dynamic price quote, you need to start off with our order form.'
            },
            {
                id: 3,
                q: 'How do I register?',
                a: 'You can register using our registration form. Alternatively, you can start with the order form and an account will be automatically created for you during checkout.'
            },
            {
                id: 4,
                q: 'How competent are your writers?',
                a: 'Being an internet company, we do recruiting globally. The Internet enables us to find qualified writers and researchers without having to gather them in some physical facility. Our employees work from their homes, however, that does not mean we just pull people off the street. Our human resource managers check every applicant very carefully, check their credentials, review their work samples, assign test projects to see if their claimed qualifications are real. We do not hire writers who fail our rigid tests.'
            },
            {
                id: 5,
                q: 'Do you guarantee to meet the deadline?',
                a: 'While we do our best to deliver the project prior to the specified deadline, your particular assignment may call for extra time due to its complexity or required depth of research. If we find that we are unable to deliver the project by the requested deadline, every step will be taken by our writers and administration to communicate that information to you promptly and discuss an extension. In case of a minor delivery delay, we will readjust the price in accordance with our price schedule or offer a discount to compensate for the inconvenience.'
            },
            {
                id: 6,
                q: 'Do you have a formalized discount program?',
                a: 'We have a discount program for return clients. You can find the details on our discounts page. If you were given a discount code for a future order, you can enter the code during the ordering process.'
            },
            {
                id: 7,
                q: 'Do you send a plagiarism report along with the completed writing',
                a: `We do have internal quality control procedures that ensure that every ${this.keyword} is authentic. Nevertheless, we do not use any third party service to check writing for plagiarism because that would be a violation of our privacy policy. Services such as Turnitin store every ${this.keyword} submitted. Once submitted to Turnitin, the ${this.keyword} is no longer original. For that reason, we do not provide any plagiarism reports.`
            },
            {
                id: 8,
                q: 'What time zone do you operate in?',
                a: 'We operate in the US Central Time zone (GMT-6 HOURS). The client is responsible to do any related adjustments to calculate the deadline.'
            },
            {
                id: 9,
                q: 'Where can I view other clients` feedback?',
                a: 'We ask that every client rate the performance of our writer and there is a dedicated tool in the client account that allows to do that easily. You can view other clients` feedback on our testimonials page.'
            },
            {
                id: 10,
                q: 'Is your service absolutely confidential?',
                a: 'Using our service is totally confidential. We do not disclose information about our clients to any third party.'
            },
            {
                id: 11,
                q: 'What payment methods do you offer? What credit cards do you accept?',
                a: 'We have two separate payment gateways: Worldpay.com (London, GB) and Paypal. The buyer can select the desired gateway during the ordering process. RBS Worldpay provides fast and secure card authorization directly on our site for VISA and MasterCard users. If Paypal is chosen, the buyer will be taken to a secure webpage that will accept the credit card information. Paypal accepts all major credit debit cards, Paypal funds and on-line checks (the buyer can pay with a bank account).'
            },
            {
                id: 12,
                q: 'What are the config you are offering and who can use them?',
                a: `We offer a wide range of writing and research config in all academic and professional fields. Some of our common academic projects are term ${this.keyword}, research ${this.keyword}, essays, dissertations, theses, book reports, article critiques, movie reviews, journal reviews, admission essays, applications, resumes. Those are done on all academic levels -- Secondary School, High School, College University (Freshman, Sophomore, Junior, Senior), Graduate Master and Ph.D. In addition, we provide such special config as PowerPoint Presentations, Calculations and Quantitative Projects for the Business School in Finance, Accounting, Intermediate Accounting, Statistics. Editing and proofreading config are also available. For those outside of the academia, we are pleased to offer professional writing config that include (but are not limited to) grant proposals, publications, professional reviews of any kind on absolutely anything, website content development.`
            },
            {
                id: 13,
                q: 'I need a 5 paragraph High School essay reflecting my own thoughts on the subject. How can that be done?',
                a: 'We assist with all types of essay assignments: definition essays, 5 paragraph essays, reflective essays, informative essays, compare and constrast essays, personal essays, admission essays, argumentative essays, etc. When filling out the order form it is recommended that you specify your take on various points to be discussed in the essay. For example, if we are writing a custom essay on Abortion, our writer needs to know your position on that subject. Otherwise, if your position is not stated, our writer will contact you and ask how you want that done.'
            },
            {
                id: 14,
                q: `I need a custom essay or a custom term ${this.keyword}. How "custom" will my ${this.keyword} be?`,
                a: `We have certain guarantees that we want our clients to be aware of. Number one, we guarantee that our written works (custom term ${this.keyword}, custom essays, custom book reports or any other custom written college ${this.keyword}) will be 100% free of plagiarism. And that is not just words. Even though we only keep the writers we can trust, we still continue to run every ${this.keyword} through a plagiarism detection software. Number two, we guarantee that the result will not just be a non-plagiarized ${this.keyword} but a non-plagiarized ${this.keyword} that meets all your initial instructions and guidelines. You can be sure that you will get exactly what you wanted, or you will simply get your money back entirely, which is another guarantee that we make to all our clients.`
            },
            {
                id: 15,
                q: 'How do I place an order with your firm?',
                a: 'It is really quite easy. All you have to do is submit all project details by filling out and submitting our order form. Thereafter, you will be prompted to provide your billing details so that we can verify that your card has adequate funds to cover the transaction. Once your billing information has been verified (takes only a minute), our writers start working on your project immediately. The process is layed out in detail on our order process page.'
            },
            {
                id: 16,
                q: 'What are the chances that anyone finds out I have used your service?',
                a: 'The chances are really close to zero because all your contact information and all order related details are protected by our stringent privacy policy. For that reason, our service can be considered 100% secure and confidential.'
            },
            {
                id: 17,
                q: 'Why do you require my billing information and is it safe to provide it to your firm?',
                a: 'Just like any on-line company providing intangible goods config, we have to require your billing information before we start working on your assignment. We have to make sure that you intend to pay for our service and therefore need to verify that your card holds adequate funds to cover the transaction. Your credit card information will be processed through a secure SSL encrypted gateway, will not be stored anywhere and will not be accessible by a human being. The authorization process is completely safe and secure as all data is first encrypted and then transmitted directly to your card issuer for verification. Thus, for security reasons, we will never accept your billing information via e-mail, phone or fax.'
            },
            {
                id: 18,
                q: 'What format will I receive my custom essay in?',
                a: 'All our writers adhere to our strict formation specifications: 12 point Times New Roman, double spacing, 1 inch margins on all 4 sides, a minimum of 280 words per page. All text will be typed in Microsoft Word XP and saved as a Microsoft Word document (.DOC). The file will retain all editing capabilities so you will be able to make any changes as needed. For projects that require additional data to be supplied (graphs, tables, slides, spreadsheets, images, etc.), we may use Microsoft Excel (.XLS), Powerpoint (.PPT), Adobe Acrobat (.PDF) or ADOBE PHOTOSHOP (.JPG,.GIF, etc.).'
            },
            {
                id: 19,
                q: `Will I be able to edit the custom term ${this.keyword} provided by your firm?`,
                a: `The ${this.keyword} will be typed in Microsoft Word and will be saved as a Word document (.doc). It will be fully editable.`
            },
            {
                id: 20,
                q: 'Do you guarantee 100% Customer Satisfaction?',
                a: 'We guarantee free unlimited revisions, rewrites or ammendments to every client, provided our writers overlooked the original instructions. If you place your order and forget to include something, you should contact our customer support immediately and supply any additional instructions.'
            },
            {
                id: 21,
                q: 'What if I am not satisfied with the result?',
                a: `We use our terms of service to handle every dissatisfaction case. If you are dissatisfied with the result, we will have the ${this.keyword} revised or rewritten until the initial guidelines are fully met. If there are some minor issues (some instructions were overlooked or some points missed) the buyer needs to submit a revision request and all those issues will be corrected promptly. If you believe the writer that did the job was unqualified, you can request a different writer. We have dozens of writers to reassign the project to in case you are not happy with the job of your current writer.`
            },
            {
                id: 23,
                q: 'What is your minimum turnaround time?',
                a: 'Our writers can work on a project with a minimum deadline of 3 hours (for short orders 1-3 pages), but if we find that we are unable to deliver the project by the requested deadline, every step will be taken by our writers and administration to communicate that information to you promptly and discuss an extension. In case of a minor delivery delay, we will readjust the price in accordance with our price schedule or offer a discount to compensate for the inconvenience.'
            },
            {
                id: 24,
                q: 'Can I message the writer directly?',
                a: 'Sure. First, log in to your account on our site. Once logged in, go to "Projects", click the drop down arrow of the order ID then click "more info". After that, go to "Messages" then you may now message the writer with regard to your concern and do not forget to click "reply" once done.'
            },
            {
                id: 25,
                q: 'Is this cheating?',
                a: `In our opinion, there is nothing unethical about asking for help. When you order an essay online, you realize that maybe you need more academic support to successfully complete your studies. Deciding to buy a term ${this.keyword} is the same as deciding to hire a tutor. You are not doing anything wrong by looking for a reasonable solution to your academic problems.`
            },
            {
                id: 26,
                q: 'Do you charge for title and reference pages?',
                a: 'Our title and reference pages are free of charge.'
            },
            {
                id: 27,
                q: `How much do you charge for single spaced ${this.keyword}?`,
                a: `1 single spaced ${this.keyword} is equivalent to 2 double spaced ${this.keyword} as it will requires more content.`
            }
        ]
        return faq
    }
}
