import { getCookie, setCookie, deleteCookie } from '@/services/cookie';

export const getUTMMedium = () => getCookie('utm_medium')
export const getUTMSource = () => getCookie('utm_source')
export const getUTMCampaign = () => getCookie('utm_campaign')

export const setUTMMedium = (param) => {
    setCookie('utm_medium', param, { secure: true, 'max-age': 31536000 })
}
export const setUTMSource = (param) => {
    setCookie('utm_source', param, { secure: true, 'max-age': 31536000 })
}
export const setUTMCampaign = (hash) => {
    setCookie('utm_campaign', hash, { secure: true, 'max-age': 31536000 })
}

export const deleteUTMMedium = () => {
    deleteCookie('utm_medium')
}
export const deleteUTMSource = () => {
    deleteCookie('utm_source')
}

export const deleteUTMCampaign = () => {
    deleteCookie('utm_campaign')
}
