export const getters = {
    getterNotification(state) {
        return state.notification
    },
    getterNotificationTotal(state) {
        return state.total
    },
    getterTypes(state) {
        return state.pricing.type
    },
    getterTurnarounds(state) {
        return state.pricing.turnaround
    },
    getterLevels(state) {
        return state.pricing.level
    },
    getterStyle(state) {
        return state.pricing.style
    },
    getterSubjects(state) {
        return state.pricing.subject
    }
}
