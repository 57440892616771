<template>
    <div class="messenger-layout">
        <router-view />
    </div>
</template>

<script>
export default {
    name: 'MessengerLayout'
}
</script>
