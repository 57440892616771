const Friends = () => import('@/views/account/Friends/FriendsPage.vue')
const FriendDetail = () => import('@/views/account/Friends/FriendDetail.vue')
const FriendsAbout = () => import('@/views/account/Friends/FriendsAbout.vue')

export default [
    {
        path: '/referrals',
        component: Friends,
        name: 'friends',
        meta: {
            requiresAuth: true,
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                name: 'Referrals',
                additionalClass: 'bc-horizontal'
            }
        }
    },
    {
        path: '/referrals/:id',
        component: FriendDetail,
        name: 'friend_detail',
        meta: {
            requiresAuth: true,
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                name: 'Friend’s profile',
                additionalClass: 'bc-wrapper bc-horizontal',
                parent_route_names: ['friends']
            }
        }
    },
    {
        path: '/referrals/about',
        component: FriendsAbout,
        name: 'friends_about',
        meta: {
            requiresAuth: true,
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                name: 'Referral Program Details',
                additionalClass: 'bc-horizontal',
                excerpt: 'Our friend referral program is a great way to earn the virtual money to spend on your assignments with our service. Plus, it is a great way to help your friends!'
            }
        }
    }
]
