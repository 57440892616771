<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.9 21.8C9.39706 21.8 9.8 21.3971 9.8 20.9C9.8 20.4029 9.39706 20 8.9 20C8.40294 20 8 20.4029 8 20.9C8 21.3971 8.40294 21.8 8.9 21.8Z"
            stroke="white"
            stroke-width="1.8"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M19.9 21.8C20.3971 21.8 20.8 21.3971 20.8 20.9C20.8 20.4029 20.3971 20 19.9 20C19.4029 20 19 20.4029 19 20.9C19 21.3971 19.4029 21.8 19.9 21.8Z"
            stroke="white"
            stroke-width="1.8"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M1 1H5L7.68 14.39C7.77144 14.8504 8.02191 15.264 8.38755 15.5583C8.75318 15.8526 9.2107 16.009 9.68 16H19.4C19.8693 16.009 20.3268 15.8526 20.6925 15.5583C21.0581 15.264 21.3086 14.8504 21.4 14.39L23 6H6"
            stroke="white"
            stroke-width="1.8"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
<script>
export default {
    name: 'OrderBasket'
}
</script>
