<template>
    <fieldset class="form-masked-input__fieldset">
        <legend
            class="form-masked-input__legend"
            :class="{ 'error-font-color': validationError, 'success': isValid}"
            :style="{'background-color': bg_color}"
        >
            {{ legend }}
        </legend>
        <TheMask
            class="form-masked-input__input"
            :class="{ 'error-border': validationError, 'success': isValid}"
            :style="{'background-color': bg_color, color: color}"
            :value="field"
            :mask="mask"
            type="text"
            inputmode="numeric"
            pattern="[0-9]*"
            :placeholder="placeholder"
            :name="name"
            @input="onChange($event)"
            @blur="blur()"
        />
        <div
            v-if="validationError"
            class="form-masked-input__error"
            :style="{'background-color': bg_color}"
        >
            {{ validationError }}
        </div>
    </fieldset>
</template>

<script>
import { TheMask } from 'vue-the-mask';

export default {
    name: 'FormMaskedInput',
    components: {
        TheMask
    },
    props: {
        value: {
            type: String
        },
        legend: {
            type: String
        },
        type: {
            type: String
        },
        name: {
            type: String
        },
        field: {
            type: String
        },
        errors: {
            type: String
        },
        isValid: {
            type: Boolean
        },
        placeholder: {
            type: String
        },
        mask: {
            type: String
        },
        char: {
            type: String
        },
        bg_color: {
            type: String
        },
        color: {
            type: String
        }
    },
    data() {
        return {
            val: ''
        };
    },
    computed: {
        validationError() {
            return this.errors ? this.errors : '';
        }
    },
    methods: {
        onChange(event) {
            this.$emit('update:field', event);
        },
        emitEvent() {
            this.$emit('clicked');
        }
    }
};
</script>

<style lang="scss">
.form-masked-input {
    &__prepend-icon {
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translateY(-50%);
        color: rgba(0, 0, 0, 0.54);
        & + .form-masked-input__input {
            padding-left: 40px;
        }
    }
    &__append-icon {
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
        color: rgba(0, 0, 0, 0.54);
    }

    &__fieldset {
        border: $input-border-success;
        border-radius: $input-border-radius;
        max-height: 50px;
        height: 50px;
        padding: 0;
        position: relative;
        width: 100%;
        &.error-border {
            border: $input-border-error;
            .form-masked-input__error {
                padding: 0 3px;
            }
        }
    }

    &__legend {
        display: table;
        color: #b3bbc6;
        caret-color: #b3bbc6;
        padding: 2px 5px 2px 5px;
        font-size: 80%;
        position: absolute;
        left: 6px;
        top: -10px;
        background-color: #233C67;
        border: none;
    }

    &__input {
        box-sizing: border-box;
        display: inline-block;
        padding-left: 10px;
        width: 100%;
        user-select: none;
        line-height: 46px;
        border: none;
        outline: none;
        border-radius: $input-border-radius;
        font-family: $font-family-base, sans-serif;
        max-height: 48px;
        background-color: #233C67;
        color: white;
    }
    &__error {
        display: inline;
        width: auto;
        max-width: 80px;
        text-align: left;
        color: $error-color;
        caret-color: $error-color;
        font-size: 12px;
        min-height: 14px;
        position: absolute;
        left: 10px;
        bottom: -7px;
        background-color: #233C67;
    }
    &__visa {
        background-color: #233C67;
    }
    &__mastercard {
        background-color: #0D2629;
    }
}

@media (max-width: 480px) {
    .form-masked-input {
        &__error{
            max-width: 120px;
        }
    }
}
</style>
