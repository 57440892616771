import { state } from './state.js';
import { mutations } from './mutations.js';
import { actions } from './actions.js';
import { getters } from './getters.js';

export const messenger = {
    namespaced: true,
    state() {
        return { ...state }
    },
    mutations,
    actions,
    getters
};
