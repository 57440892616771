import { cloneDeep } from 'lodash'

import { SchemaCreditCard } from '@/models/SchemaCreditCard.js'

export const initialState = {
    form_data_credit_card: new SchemaCreditCard(),
    loading: {
        status: false,
        message: ''
    },
    payment: {
        isLoading: false,
        account_balance: 0,
        total: 0,
        payable: 0,
        total_store_credit: 0,
        orders: 0
    },
    orders: [],
    payment_error: null,
    bitfinex: null
}

export const state = cloneDeep(initialState)
