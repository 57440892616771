import store from '../../store'

const TicketsList = () => import('@/views/account/HelpCenter/TicketsList.vue')
const TicketsDetails = () => import('@/views/account/HelpCenter/TicketsDetails.vue')
const SupportForm = () => import(/* webpackChunkName: "_support-form-page" */'@/views/support/Main.vue')
const SupportGuest = () => import(/* webpackChunkName: "_support-form-page" */'@/views/support/TicketGuest.vue')
const supportMessenger = () => import(/* webpackChunkName: "_support-form-page" */'@/views/supportMessenger/index.vue')

console.log(store.getters['client/getterIsAuth']);
export default [
    {
        path: '/help-center',
        component: TicketsList,
        name: 'tickets',
        meta: {
            keepAlive: true,
            requiresAuth: true,
            layout: 'CustomBreadcrumbsLayout',
            showHeader: true,
            breadcrumbs: {
                name: 'Help Center',
                additionalClass: 'bc-horizontal  ticket-bc-wrapper',
                excerpt: 'If you have an issue with your order, you can create a support ticket and get the problem resolved. Our support agents respond very quickly and work 24/7.'
            }
        }
    },
    {
        path: '/help-center/:id',
        component: TicketsDetails,
        name: 'ticket_details',
        meta: {
            showLoginHeader: store.getters['client/getterIsAuth'] ? 0 : 1,
            layout: store.getters['client/getterIsAuth'] ? 'CustomBreadcrumbsLayout' : 'EmptyLayout',
            breadcrumbs: {
                name: 'Ticket Details',
                additionalClass: 'bc-horizontal bc-horizontal__details'
            }
        }
    },
    {
        path: '/support/ticket/new',
        component: SupportForm,
        name: 'support_form',
        meta: {
            showLoginHeader: store.getters['client/getterIsAuth'] ? 0 : 1,
            layout: store.getters['client/getterIsAuth'] ? 'CustomBreadcrumbsLayout' : 'EmptyLayout',
            breadcrumbs: {
                name: 'Help Center',
                excerpt: 'Our Support Center is available 24/7 (no holidays, no days off) via phone, live chat and our ticketing system. Our response time is usually just a few minutes.',
                additionalClass: 'bc-horizontal '
            }
        }
    },
    {
        path: '/support/ticket',
        component: SupportGuest,
        name: 'support_form_guest',
        meta: {
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                name: 'Help Center',
                excerpt: 'Our Support Center is available 24/7 (no holidays, no days off) via phone, live chat and our ticketing system. Our response time is usually just a few minutes.',
                additionalClass: 'bc-horizontal '
            }
        }
    },
    {
        path: '/support/chat',
        component: supportMessenger,
        name: 'support_chat',
        meta: {
            layout: 'MessengerLayout'
        }
    }
]
