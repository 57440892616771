import Auth from '@/helpers/auth/'

export const initialState = {
    auth: Auth.isLogged(),
    client_data: [],
    finalizeAuthFormData: null,
    socialModal: null
}

export const state = initialState
