<template>
    <div
        class="uploader"
        :class="{ 'uploader--dragging': isDragging }"
        @dragenter.prevent="isDragging = true"
        @dragleave.prevent="isDragging = false"
        @dragover.prevent
        @drop="onDrop"
    >
        <div
            v-show="!isUploadingInProgress"
            class="uploader_drag"
        >
            <p class="uploader_drag-text">
                Drag files here
            </p>

            <div class="uploader_input">
                <input
                    id="file"
                    ref="input"
                    type="file"
                    multiple
                    @change="onInputChange"
                >
            </div>
        </div>

        <div
            v-show="isUploadingInProgress"
            class="uploader_progress"
        >
            <circle-progress
                v-if="uploadProgress > 0"
                :progress="uploadProgress"
            />
        </div>
    </div>
</template>

<script>
import CircleProgress from '@/components/CircleProgress.vue';

export default {
    name: 'FormFilesUploader',
    components: {
        CircleProgress
    },
    props: {
        isUploadingInProgress: {
            type: Boolean,
            required: true
        },
        uploadProgress: {
            type: Number,
            required: false,
            default: 0
        }
    },
    data() {
        return {
            isDragging: false
        }
    },
    methods: {
        onDrop(e) {
            e.preventDefault();
            e.stopPropagation();
            this.isDragging = false;
            const { files } = e.dataTransfer;
            this.$emit('uploadFiles', [...Array.from(files)])
        },
        onInputChange(e) {
            const { files } = e.target;
            this.$emit('uploadFiles', [...Array.from(files)])
        }
    }
}
</script>

<style lang="scss">
    .uploader{
        width: 100%;
        min-height: 100px;
        border: 2px dashed #96a1b2;
        text-align: center;
        position: relative;
        transition: background .2s ease, border .1s ease-in;
        &:hover {
            background: rgba(100%, 41%, 5%, .1);
            border: 2px dashed $main-color;
            p {
                color: $main-color;
                svg {
                    fill: $main-color;
                }
            }
        }
        &_drag {
            padding: 50px 0;
            position: relative;

            &-text {
                margin: 0 !important;
                display: inline-block;
                color: #96a1b2;
                font-family: $font-family-base;
                font-size: 18px;
                font-weight: 500;
                text-transform: uppercase;
                position: relative;

                @include media768max{
                    padding-top: 35px;
                }

                &:before {
                    content: '';
                    position: absolute;
                    left: -35px;
                    width: 26px;
                    height: 26px;
                    top: 50%;
                    transform: translateY(-50%);
                    display: inline-block;
                    mask-image: url('~@/assets/img/svg/upload.svg');
                    -webkit-mask-image: url('~@/assets/img/svg/upload.svg');
                    background: #96a1b2;

                    @include media768max{
                        left: 50%;
                        top: 0;
                        transform: translateY(0%) translateX(-50%);
                        margin-bottom: 10px;
                    }
                }
            }
        }
        &_input {
            width: 100%;
            height: 100%;
            margin: auto;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;

            input {
                background: transparent;
                width: 100%;
                height: 100%;
                cursor: pointer;
                opacity: 0;
                z-index: auto;
            }
        }
        &_progress{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
        &--dragging {
            background: rgba(100%, 41%, 5%, .1);
            border: 2px dashed $main-color;
        }
    }
</style>
