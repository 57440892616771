export class SchemaExpressOrder {
    constructor(
        due_at = null,
        service_type = null,
        pages = 1,
        desired_price = 1,
        problems = null,
        charts = null,
        slides = null,
        title = '',
        details = '',
        additional_files = []
    ) {
        this.due_at = due_at
        this.service_type = service_type
        this.pages = pages
        this.problems = problems
        this.charts = charts
        this.slides = slides
        this.title = title
        this.details = details
        this.additional_files = additional_files
        this.desired_price = desired_price
    }
}
