export const getCookie = (name) => {
    const matches = document.cookie.match(new RegExp(
        // eslint-disable-next-line no-useless-escape
        `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}
export const setCookie = (name, value, options = {}) => {
    options = {
        path: '/',
        // при необходимости добавьте другие значения по умолчанию
        ...options
    };

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
    Object.keys(options).forEach((optionKey) => {
        updatedCookie += `; ${optionKey}`;
        const optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += `=${optionValue}`;
        }
    })

    document.cookie = updatedCookie;
}
export const deleteCookie = (name) => {
    setCookie(name, '', {
        'max-age': -1
    })
}
