export class SchemaOrder {
    constructor({
        linked_to_order = '',
        type = '',
        academiclevel = '',
        due_at = '',
        pages = 1,
        slides = 0,
        charts = 0,
        problems = 0,
        sources = '',
        style = '',
        subject = '',
        title = '',
        details = '',
        chk_writer_select_topic = false,
        chk_abstract = false,
        chk_outline = false,
        preferred_writers_parsed = [],
        blocked_writers_parsed = [],
        files_add = [],
        discount_code = '',
        session_id = ''
    } = {}) {
        this.chk_linked = Boolean(linked_to_order)
        this.linked_to_order = linked_to_order
        this.type = type
        this.academiclevel = academiclevel
        this.due_at = due_at
        this.pages = pages
        this.chk_slides = Boolean(slides)
        this.slides = slides
        this.chk_charts = Boolean(charts)
        this.charts = charts
        this.chk_problems = Boolean(problems)
        this.problems = problems
        this.chk_sources = Boolean(sources)
        this.sources = sources
        this.style = style
        this.subject = subject
        this.title = title
        this.chk_writer_select_topic = chk_writer_select_topic
        this.details = details
        this.chk_abstract = chk_abstract
        this.chk_outline = chk_outline
        this.preferred_writers = preferred_writers_parsed || []
        this.blocked_writers = blocked_writers_parsed || []
        this.chk_discount = Boolean(discount_code)
        this.discount_code = discount_code || ''
        this.files_uploaded = files_add
        this.session_id = session_id
    }
}
