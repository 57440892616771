import { cloneDeep } from 'lodash'
import { SchemaOrder } from '@/models/SchemaOrder.js'
import { CalculatorValidator } from '@/services/calculator/index.js'
import moment from 'moment'

// converts an array of objects into an array of numbers (writer ID)
export const writerListToIdList = (list) => {
    if (!Array.isArray(list)) throw new Error('Not an array')
    const idList = list.map((writer) => Number.parseInt(writer.sw_id, 10))
    return idList
}

// Фикс даты кредитки с формата **\** в ** \ ****
export const creditCardFixDate = (creditCardData) => {
    const MONTH = creditCardData.cc_expiry.substring(0, 2);
    const YEAR = creditCardData.cc_expiry.substring(2);
    delete creditCardData.cc_expiry
    return {
        ...creditCardData,
        cc_expiry_year: `20${YEAR}`,
        cc_expiry_month: MONTH
    }
}

export function humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return `${bytes} B`;
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        u += 1;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return `${bytes.toFixed(dp)} ${units[u]}`;
}

export const generateOrderFromBatch = (order, pricing) => {
    const newOrder = new SchemaOrder(order)
    newOrder.due_at = order.due_at
    newOrder.batchid = order.batchid
    const instance = new CalculatorValidator(
        cloneDeep(pricing),
        newOrder
    )
    newOrder.preferred_writers = order.pr_wr_list.trim().split(' ').filter((i) => !!i)
    newOrder.blocked_writers = order.bl_wr_list.trim().split(' ').filter((i) => !!i)
    newOrder.due_at = instance.deadline.getRefreshedDeadline()
    newOrder.sources = !newOrder.sources ? '0' : newOrder.sources
    newOrder.style = newOrder.sources === '0' ? '0' : newOrder.style
    return newOrder
}

export function removeEmptyParamsFromRequest(obj) {
    const query = Object.entries(obj)
    // eslint-disable-next-line no-unused-vars
        .filter(([_, v]) => v !== null && v !== '' && v !== 'all')
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

    if (query.search_by && !query.search_for) {
        delete query.search_by
    }

    return query
}

export const writersListToIdsList = (list) => list.map((writer) => writer?.id).filter((writer) => Boolean(writer))

export const bitfinexIsValid = (t, d) => {
    const date = moment(t).add(d, 'seconds')
    const now = moment()
    return date > now
}
