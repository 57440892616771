import Api from '@/helpers/api/index.js';

import {
    ACTION_TICKET_SUBMIT
} from './action-types.js'

import {
    CLEAR_FORM
} from './mutation-types.js'

export const actions = {
    async [ACTION_TICKET_SUBMIT]({ state, rootState, commit }, payload) {
        const { client } = rootState
        let response
        if (client.auth) {
            if (!state.form_data.data_support.linked_to_order) {
                delete state.form_data.data_support.linked_to_order;
            }
            response = await Api.post('/api/support/auth/create-ticket', {
                ...state.form_data.data_support
            })
        } else {
            let data = {
                ...state.form_data.data_support,
                ...state.form_data.data_client,
                recaptcha: payload
            }
            if (rootState.phone.verified) {
                data = {
                    ...data,
                    phone_code: `${rootState.phone.form_data.country.value}`,
                    phone: `${rootState.phone.form_data.phone_number}`
                }
            }
            response = await Api.post('/api/support/guest/create-ticket', JSON.stringify(data))
        }

        commit(CLEAR_FORM)
        commit('phone/RESET', null, { root: true })
        return response
    }
}
