import {
    required,
    minLength,
    maxLength,
    email
} from 'vuelidate/lib/validators'

export const validationRules = {
    email: {
        required,
        email
    },
    problem: {
        required,
        minLength: minLength(50),
        maxLength: maxLength(65535)
    },
    country: {
    },
    phone_number: {
    }
};
