import { getField } from 'vuex-map-fields'

export const getters = {
    getField,
    getOrderFormData(state) {
        return state.form_data
    },
    getterLoadingStatus(state) {
        return state.loading
    },
    getterActiveStep(state) {
        return state.active_step
    },
    getterFilesAdd(state) {
        return state.files_add
    }
}
